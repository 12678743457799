import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi'
    },
    theme:{
        themes: {
            light: {
                primary: '#1B1B1B',
                secondary: '#FFFFFF',
                third: '#1B1B1B'
            },
            dark: {
                primary: '#1B1B1B',
                secondary: '#FFFFFF',
                third: '#1B1B1B',
                background: '#1B1B1B',
            }
        },
    }
});
