import axios from 'axios'

// const api_endpoint = 'https://enterprise.api.docmasweetspot.ca'
const api_endpoint = 'https://staging.enterprise.api.docmasweetspot.ca'
// const api_endpoint = 'http://127.0.0.1:5001'


function get_ticker_data(token, project_id){
    let api_url = api_endpoint+'/reporting/ticker';
    let data = {"project_id": project_id};
    return axios_post_call(api_url,data,token);
}

function get_portfolio_data(token, project_id, date_range, data_filters, levels){
    let api_url = api_endpoint+'/reporting/portfolio';
    let data = {"project_id": project_id, "date_range": date_range, "data_filters": data_filters, "data_levels": levels};
    return axios_post_call(api_url,data,token);
}

function get_highlights_data(token, project_id, date_range, data_filters){
    let api_url = api_endpoint+'/reporting/highlights';
    let data = {"project_id": project_id, "date_range": date_range,"data_filters": data_filters};
    return axios_post_call(api_url,data,token);
}

function get_portfolio_overview_data(token, project_id, data_levels, date_range, data_filters){
    let api_url = api_endpoint+'/reporting/portfolio_overview';
    let data = {"project_id": project_id, "date_range": date_range, "data_levels": data_levels, "data_filters": data_filters};
    return axios_post_call(api_url,data,token);
}

function get_dashboard_data(token, project_id, channel, date_range, data_filters){
    let api_url = api_endpoint+'/reporting/dashboard';
    let data = {"project_id": project_id, "query_info": channel, "date_range": date_range, "data_filters": data_filters};
    return axios_post_call(api_url,data,token);
}

function get_breakdown_data(token, project_id, time_interval, date_range, data_levels, data_filters){
    let api_url = api_endpoint+'/reporting/breakdown';
    let data = {"project_id": project_id, "time_interval": time_interval, "date_range": date_range, "data_levels": data_levels, "data_filters": data_filters};
    return axios_post_call(api_url,data,token);
}

// function get_report_data(token, project_id, date_range, data_levels){
//     let api_url = api_endpoint+'/reporting/report';
//     let data = {"project_id": project_id, "date_range": date_range, "data_levels": data_levels};
//     return axios_post_call(api_url,data,token);
// }

// function get_response_curves_data(token, project_id, date_range, data_levels){
//     let api_url = api_endpoint+'/reporting/response_curves';
//     let data = {"project_id": project_id, "date_range": date_range, "data_levels": data_levels};
//     return axios_post_call(api_url,data,token);
// }

function get_updated_volume_efficiency(token, payload){
    let api_url = api_endpoint + '/reporting/volume_vs_efficiency';
    return axios_post_call(api_url, payload, token);
}


/*
Base Caller functions
*/
function axios_post_call(api_url,data,token){
    return new Promise(function(resolve,reject){
        axios.post(api_url, data, {headers: {Authorization: `Bearer ${token}`}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        }).catch((e)=>{
            reject(api_url+e);
        })
    })
}

export default {get_portfolio_data, get_portfolio_overview_data, get_dashboard_data, get_breakdown_data, get_highlights_data, get_updated_volume_efficiency, get_ticker_data}