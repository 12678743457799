<template>
  <v-container class="px-6 pt-0 pb-3" style="max-width: 100%;">
    <v-row class="ma-0 pa-0">
      <v-col cols="auto" class="pt-0">
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0" cols="auto">
            <v-btn
              icon
              class="toolButton"
              color="#323232"
              :disabled="selected_level_val == available_level_vals[0]"
              @click="updateLevelVal(-1)"
            >
              <v-icon large>mdi-arrow-left-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col class="ma-0 pa-0" cols="auto">
            <v-select
              class="ma-0 pa-0"
              style="width: 400px"
              v-model="selected_level_val"
              :items="available_level_vals"
              hide-details
            >
              <template v-slot:selection="{ item }">
                <span class="graph_select_title">{{
                  item.replaceAll("__", " - ").replaceAll("_", " ")
                }}</span>
              </template>
              <template v-slot:item="{ item }">
                <span class="graph_select_options">{{
                  item.replaceAll("__", " - ").replaceAll("_", " ")
                }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col class="ma-0 pa-0" cols="auto">
            <v-btn
              icon
              class="toolButton"
              color="#323232"
              :disabled="
                selected_level_val ==
                available_level_vals[available_level_vals.length - 1]
              "
              @click="updateLevelVal(1)"
            >
              <v-icon large>mdi-arrow-right-circle</v-icon>
            </v-btn>
          </v-col>
          <!-- <v-col class="ma-0 pa-0 reported_roas_title" cols="auto" style="width: 60px">
              <span>{{reported_roas}}</span>
            </v-col> -->
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" class="pt-0">
        <div class="toolBar">
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="toolButton"
                color="#323232"
                v-on="on"
              >
                <v-icon large>mdi-information-variant</v-icon>
              </v-btn>
            </template>
            <TooltipDescription
              title="Data Dashboard"
              desc_2="You can scroll to zoom in and out of the graph, and drag the graph to pan
              left or right. To restore to default view, simply right click on the graph."
            ></TooltipDescription>
          </v-tooltip> -->

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="toolButton"
                color="#323232"
                v-on="on"
                @click="axisLockChanged(lockVAxis ? false : true, 'vAxis')"
              >
                <v-icon large :color="lockVAxis ? '#949494' : '#000000'"
                  >mdi-arrow-vertical-lock</v-icon
                >
              </v-btn>
            </template>
            <TooltipDescription title="Vertical Axis Lock"></TooltipDescription>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="toolButton"
                color="#323232"
                v-on="on"
                @click="axisLockChanged(lockHAxis ? false : true, 'hAxis')"
              >
                <v-icon large :color="lockHAxis ? '#949494' : '#000000'"
                  >mdi-arrow-horizontal-lock</v-icon
                >
              </v-btn>
            </template>
            <TooltipDescription title="Horizontal Axis Lock"></TooltipDescription>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="toolButton"
                color="#323232"
                v-on="on"
                @click="
                  indicators_drawer = !indicators_drawer;
                  finance_drawer = false;
                  reload_graph();
                "
              >
                <v-icon large :color="!indicators_drawer ? '#000000' : '#949494'"
                  >mdi-function</v-icon
                >
              </v-btn>
            </template>
            <TooltipDescription title="Indicators Drawer"></TooltipDescription>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="toolButton"
                color="#323232"
                v-on="on"
                @click="
                  finance_drawer = !finance_drawer;
                  indicators_drawer = false;
                  reload_graph();
                "
              >
                <v-icon large :color="!finance_drawer ? '#000000' : '#949494'"
                  >mdi-finance</v-icon
                >
              </v-btn>
            </template>
            <TooltipDescription title="Response Curve"></TooltipDescription>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="toolButton"
                color="#323232"
                v-on="on"
                @click="open_fullscreen_chart()"
              >
                <v-icon large>mdi-fullscreen</v-icon>
              </v-btn>
            </template>
            <TooltipDescription title="Fullscreen Mode"></TooltipDescription>
          </v-tooltip>
          <!-- <v-btn
            disabled
            icon
            class="toolButton"
            color="black"
            @click="settingOverlay = true"
          >
            <v-icon large>mdi-cog</v-icon>
          </v-btn> -->
        </div>
      </v-col>
    </v-row>
    <!-- <v-row
      class="ma-0 pa-0"
      justify="center"
      align="center"
      v-if="!graph_visible"
    >
      <v-col cols="auto">
        <v-progress-circular
          size="100"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row> -->
    <v-row class="ma-0 pa-0" style="position: relative">
      <!--Loading-->
      <v-col :cols="indicators_drawer || finance_drawer ? 9 : 12">
        <v-col
        cols="auto"
        class="ma-0 pa-0"
        v-if="!graph_visible"
        align="center"
        justify="middle"
        >
          <v-progress-circular
            size="100"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
        <!--Main Graph-->
        <v-col
          cols="auto"
          id="mainChartWrapper"
          v-if="graph_visible"
        >
          <div
            id="mainChart"
            :class="
              mini_charts_list.length > 0
                ? 'resizableChart chartWithOverlay'
                : +'chartWithOverlay'
            "
            :style="{
              height: mainChartHeight + 'px',
              maxHeight: screenHeight * 0.95 +'px'
            }"
          >
            <GChart
              :type="chartConfig.chartType"
              :data="rawData"
              :options="chartConfig.mainChartOptions"
              class="performanceChart"
              :resizeDebounce="50"
              :events="chartEvents"
              ref="mainChart"
              :key="mainChartKey"
            />
          </div>
          <div
            class="miniChartWithOverlay"
            v-for="chart in mini_charts_list"
            :key="chart.graph_id"
          >
            <GChart
              type="ComboChart"
              :data="chart.data"
              :options="chart.config"
              class="performanceChart"
              :resizeDebounce="50"
              :key="miniChartKey"
              :style="{
                height: miniChartHeight + 'px',
              }"
            />
          </div>
          <!-- <div class="chartWithOverlay" v-if="mini_graph_visible">
            <GChart
              :type="chartConfig.chartType"
              :data="miniGraphData"
              :options="chartConfig.miniChartOptions"
              class="performanceChart"
              :resizeDebounce="50"
            />
          </div> -->
        </v-col>
      </v-col>
      <!--Finance Drawer-->
      <v-col cols="3" v-if="finance_drawer">
        <v-card
          :style="{
            height: screenHeight,
            'max-height': screenHeight + 'px'
          }"
          flat
        >
          <v-card style="height: 250px" flat class="mb-6">
            <GChart
              style="height: 250px"
              type="LineChart"
              :data="response_curve_dataset"
              :options="response_curve_config"
              :resizeDebounce="50"
            />
          </v-card>
          <v-card flat>
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 py-1">
                <span class="chart_finance_metric_title">ROAS:</span>
                <span class="chart_finance_metric">{{ reported_roas }}</span>
              </v-col>
              <v-col cols="12" class="ma-0 py-1">
                <span class="chart_finance_metric_title">Synergy: </span>
                <span class="chart_finance_metric">{{ reported_synergy }}</span>
              </v-col>
              <v-col cols="12" class="ma-0 py-1">
                <span class="chart_finance_metric_title">Spend: </span>
                <span class="chart_finance_metric">{{ reported_spend }}</span>
              </v-col>
              <v-col cols="12" class="ma-0 py-1">
                <span class="chart_finance_metric_title">Impressions: </span>
                <span class="chart_finance_metric">{{ reported_impr }}</span>
              </v-col>
              <v-col cols="12" class="ma-0 py-1">
                <span class="chart_finance_metric_title">CPM: </span>
                <span class="chart_finance_metric">{{ reported_cpm }}</span>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-col>

      <!--Indicators Drawer-->
      <v-col cols="3" v-if="indicators_drawer">
        <v-card :style="{ 'max-height': screenHeight + 'px', 'overflow-y': 'auto' }">
          <v-card-title>
            <v-icon class="ml-0 mr-1" color="black" large>mdi-function</v-icon>
            <span class="metric_toolbar_title">Indicators</span>
            <v-spacer></v-spacer>
            <v-icon
              color="black"
              large
              @click="
                indicators_drawer = false;
                reload_graph();
              "
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-row style="width: 100%; height: 100%" class="ma-0 pa-0">
            <v-treeview
              :items="indicator_list_breakdown"
              item-key="metric_id"
              item-text="metric_name"
              style="width: 100%"
            >
              <template v-slot:prepend="{ item }">
                <v-icon
                  class="mr-1"
                  v-if="item.graph_color"
                  :color="
                    Array.isArray(item.graph_color)
                      ? item.graph_color[item.graph_color.length - 1]
                      : item.graph_color
                  "
                  >mdi-circle</v-icon
                >
              </template>
              <template v-slot:label="{ item }">
                <span class="metric_selection_title" v-if="!item.description_short">{{
                  item.metric_name
                }}</span>
                <span class="metric_selection_metric_title" v-else>{{
                  item.metric_name
                }}</span>
              </template>
              <template v-slot:append="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" v-if="item.description_short">
                      <v-icon class="ma-0 pa-0" color="#323232"
                        >mdi-information-variant</v-icon
                      >
                    </v-btn>
                  </template>
                  <span class="tooltip_description">{{ item.description_short }}</span>
                </v-tooltip>

                <v-icon
                  v-if="
                    item.description_short &&
                    !selected_metric_ids.includes(item.metric_id)
                  "
                  color="black"
                  class="mx-1"
                  @click="select_indicator(item.metric_id)"
                  >mdi-eye
                </v-icon>
                <v-icon
                  v-if="
                    item.description_short && selected_metric_ids.includes(item.metric_id)
                  "
                  color="black"
                  class="mx-1"
                  @click="remove_indicator(item.metric_id)"
                  >mdi-eye-off-outline
                </v-icon>
                <!-- <v-icon
                      v-if="user_profile && user_profile['preset_metrics'].includes(item.metric_id)"
                      color="yellow"
                      class="mx-1"
                      >mdi-star
                    </v-icon> -->
              </template>
            </v-treeview>
          </v-row>
        </v-card>
      </v-col>
      <!--Graph Indicators Overlay-->
      <div class="metricToggleOverlay" v-if="graph_visible">
        <div>
          <v-row class="ma-0 pa-0">
            <v-col
              cols="auto"
              class="ma-0 pa-0"
              style="display: flex; align-items: center; justify-content: center; "
            >
              <v-icon color="black" class="mx-1">mdi-function</v-icon>
              <span class="metric_overlay_title">Indicators</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="ma-0 pa-0">
              <v-btn
                icon
                @click="overlay_indicators_visible = false"
                v-if="overlay_indicators_visible"
              >
                <v-icon color="#323232">mdi-window-minimize</v-icon>
              </v-btn>
              <v-btn icon @click="overlay_indicators_visible = true" v-else>
                <v-icon color="#323232">mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div
          style="display: flex; flex-direction: column"
          v-if="overlay_indicators_visible"
        >
          <div
            class="metricToggleRow"
            v-for="indicator in selected_metrics.slice(
              0,
              overlay_indicators_expanded ? selected_metrics.length : 5
            )"
            :key="indicator.metric_id"
          >
            <span class="metric_overlay_metric_title">
              <v-icon
                class="mr-1"
                :color="
                  Array.isArray(indicator.graph_color)
                    ? indicator.graph_color[indicator.graph_color.length - 1]
                    : indicator.graph_color
                "
                >mdi-circle</v-icon
              >
              {{ indicator.metric_name }}
            </span>
            <span
              class="metric_overlay_metric_stat"
              style="width: 70px; display: inline-block; text-align: right"
            >
              {{
                tooltipMouseOverRow && indicator.visible
                  ? dataset[tooltipMouseOverRow][indicator.metric_stat_headers] &&
                    dataset[tooltipMouseOverRow][indicator.metric_stat_headers].toFixed(2)
                  : ""
              }}
            </span>
            <v-btn
              icon
              class="toolButton"
              color="black"
              @click="update_graph(indicator.metric_id)"
            >
              <v-icon v-if="indicator.visible" large>mdi-eye</v-icon>
              <v-icon v-if="!indicator.visible" large>mdi-eye-off-outline</v-icon>
            </v-btn>
            <!-- <v-btn
              icon
              class="toolButton"
              color="black"
            >
              <v-icon 
                v-if="user_profile && user_profile['preset_metrics'].includes(indicator.metric_id)"
                medium
                color="yellow"
              >
                mdi-star
              </v-icon>
              <v-icon 
                  v-else
                  medium
                  color="yellow"
                >
                  mdi-star-outline
              </v-icon>
            </v-btn> -->
          </div>
        </div>
        <div
          v-if="selected_metrics.length > 5 && !overlay_indicators_expanded"
          class="metricToggleRow"
          align="center"
        >
          <v-icon @click="overlay_indicators_expanded = true"
            >mdi-chevron-double-down</v-icon
          >
        </div>
        <div
          v-if="selected_metrics.length > 5 && overlay_indicators_expanded"
          class="metricToggleRow"
          align="center"
        >
          <v-icon @click="overlay_indicators_expanded = false"
            >mdi-chevron-double-up</v-icon
          >
        </div>
      </div>
    </v-row>
    <!-- OVERLAY -->
    <v-row class="ma-0 pa-0" v-if="fullScreenChartOverlay">
      <v-col cols="12">
        <v-overlay :value="fullScreenChartOverlay">
          <v-card style="height: 100%; width: 100%" class="pa-3">
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" cols="auto">
                <v-btn
                  icon
                  class="toolButton"
                  color="#323232"
                  :disabled="selected_level_val == available_level_vals[0]"
                  @click="updateLevelVal(-1)"
                >
                  <v-icon color="white" large>mdi-arrow-left-circle</v-icon>
                </v-btn>
              </v-col>
              <v-col class="ma-0 pa-0" cols="auto">
                <v-select
                  class="ma-0 pa-0"
                  style="width: 400px"
                  v-model="selected_level_val"
                  :items="available_level_vals"
                >
                  <template v-slot:selection="{ item }">
                    <span class="graph_select_title">{{
                      item.replaceAll("__", " - ").replaceAll("_", " ")
                    }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span class="graph_select_options">{{
                      item.replaceAll("__", " - ").replaceAll("_", " ")
                    }}</span>
                  </template>
                </v-select>
              </v-col>
              <v-col class="ma-0 pa-0" cols="auto">
                <v-btn
                  icon
                  class="toolButton"
                  color="#323232"
                  :disabled="
                    selected_level_val ==
                    available_level_vals[available_level_vals.length - 1]
                  "
                  @click="updateLevelVal(1)"
                >
                  <v-icon color="white" large>mdi-arrow-right-circle</v-icon>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="ma-0 pa-0" cols="auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="toolButton"
                      color="#323232"
                      v-on="on"
                      @click="axisLockChanged(lockHAxis ? false : true, 'hAxis')"
                    >
                      <v-icon large :color="lockHAxis ? '#949494' : '#FFFFFF'"
                        >mdi-arrow-horizontal-lock</v-icon
                      >
                    </v-btn>
                  </template>
                  <TooltipDescription title="Horizontal Axis Lock"></TooltipDescription>
                </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0" cols="auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="toolButton"
                      color="#323232"
                      v-on="on"
                      @click="axisLockChanged(lockVAxis ? false : true, 'vAxis')"
                    >
                      <v-icon large :color="lockVAxis ? '#949494' : '#FFFFFF'"
                        >mdi-arrow-vertical-lock</v-icon
                      >
                    </v-btn>
                  </template>
                  <TooltipDescription title="Vertical Axis Lock"></TooltipDescription>
                </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0" cols="auto">
                <v-btn
                  @click="
                    fullScreenChartOverlay = false;
                    reload_graph();
                  "
                  class="overlayButton"
                  icon
                >
                  <v-icon color="white" large>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-row class="ma-0 pa-0">
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn @click="fullScreenChartOverlay = false" class="overlayButton">
                  Close
                </v-btn>
              </v-col>
            </v-row> -->
            <v-row class="ma-0 pa-0">
              <v-col
                cols="12"
                class="ma-0 pa-0"
                v-if="!graph_visible"
                align="center"
                justify="middle"
              >
                <v-progress-circular
                  size="100"
                  indeterminate
                  color="secondary"
                ></v-progress-circular>
              </v-col>
              <v-col cols="12" v-if="graph_visible">
                <div
                  id="fullScreenMainChart"
                  class="chartWithOverlay"
                  :style="{
                    height: 750 - mini_charts_list.length * miniChartHeight + 'px',
                  }"
                >
                  <GChart
                    :type="fullScreenChartConfig.chartType"
                    :data="rawData"
                    :options="fullScreenChartConfig.mainChartOptions"
                    :resizeDebounce="50"
                    :events="chartEvents"
                    ref="fullScreenMainChart"
                    class="performanceChart"
                  />
                </div>
                <div
                  v-for="chart in mini_charts_list"
                  :key="chart.graph_id"
                  id="fullScreenMiniChart"
                  class="miniChartWithOverlay"
                >
                  <GChart
                    type="ComboChart"
                    :data="chart.data"
                    :options="chart.config"
                    :resizeDebounce="50"
                    :ref="chart.graph_id"
                    class="performanceChart"
                  />
                </div>
              </v-col>
              <div class="metricToggleOverlayOverlay" v-if="graph_visible">
                <div>
                  <v-row class="ma-0 pa-0">
                    <v-col
                      cols="auto"
                      class="ma-0 pa-0"
                      style="display: flex; align-items: center; justify-content: center"
                    >
                      <v-icon color="black" class="mx-1">mdi-function</v-icon>
                      <span class="metric_overlay_title">Indicators</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                      <v-btn
                        icon
                        @click="overlay_indicators_visible = false"
                        v-if="overlay_indicators_visible"
                      >
                        <v-icon color="#323232">mdi-window-minimize</v-icon>
                      </v-btn>
                      <v-btn icon @click="overlay_indicators_visible = true" v-else>
                        <v-icon color="#323232">mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div
                  style="display: flex; flex-direction: column"
                  v-if="overlay_indicators_visible"
                >
                  <div
                    class="metricToggleRow"
                    v-for="indicator in selected_metrics"
                    :key="indicator.metric_id"
                  >
                    <span class="metric_overlay_metric_title">
                      <v-icon
                        class="mr-1"
                        :color="
                          Array.isArray(indicator.graph_color)
                            ? indicator.graph_color[indicator.graph_color.length - 1]
                            : indicator.graph_color
                        "
                        >mdi-circle</v-icon
                      >
                      {{ indicator.metric_name }}
                    </span>
                    <span
                      class="metric_overlay_metric_stat"
                      style="width: 70px; display: inline-block; text-align: right"
                    >
                      {{
                        tooltipMouseOverRow && indicator.visible
                          ? dataset[tooltipMouseOverRow][indicator.metric_stat_headers] &&
                            dataset[tooltipMouseOverRow][
                              indicator.metric_stat_headers
                            ].toFixed(2)
                          : ""
                      }}
                    </span>
                    <v-btn
                      icon
                      class="toolButton"
                      color="black"
                      @click="update_graph(indicator.metric_id)"
                    >
                      <v-icon v-if="indicator.visible" large>mdi-eye</v-icon>
                      <v-icon v-if="!indicator.visible" large>mdi-eye-off-outline</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-row>
          </v-card>
        </v-overlay>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { GChart } from "vue-google-charts/legacy";

//Demo Data
import chartData from "@/input_data/performance_chart_data.json";
import chartDataTest from "@/input_data/Performance/MMM_daily.json";
import user_setting_test from "@/test_data/user_profile_test.json";

// Configs
import chartConfig from "@/configs/Performance/performance_chart_config.json";
import indicator_list from "@/configs/Performance/available_indicators.json";

import responseChartConfig from "@/configs/Performance/performance_sole_response_curve_config.json";

import api_caller from "@/javascript/data_retrieval.js";
const lodash = require("lodash");

import {
  format_data_to_gchart,
  calculate_exponential_moving_average,
  calculate_simple_moving_average,
  calculate_simple_moving_average_cross,
  calculate_bollinger_band,
  compute_minichart_volume,
  set_marker_line,
  calculate_average_true_range,
  calculate_keltner_channels,
  calculate_MACD,
} from "@/components/Performance/PerformanceStats/statistics.js";

import TooltipDescription from "@/components/Utility/TooltipDescription.vue";

import { ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE } from "@/constants/constants";

// const CHANNEL_LIST = ["TV", "Radio", "OOH", "Social", "Programmatic"];

export default {
  name: "PerformanceStatsGraph",
  props: ["project_date_range", "selected_channel", "data_levels", "data_filters"],
  components: {
    GChart,
    TooltipDescription,
  },
  methods: {
    axisLockChanged: function (toggle, axis) {
      if (toggle) {
        const chart = this.$refs.mainChart.chartObject;
        var chartLayout = chart.getChartLayoutInterface();
        var chartBounds = chartLayout.getChartAreaBoundingBox();

        if (axis == "hAxis") {
          this.lockVAxis = false;
          this.lockHAxis = true;
          this.chartConfig.mainChartOptions["explorer"]["axis"] = "vertical";
          this.chartConfig.mainChartOptions["hAxis"]["viewWindow"] = {
            min: chartLayout.getHAxisValue(chartBounds.left),
            max: chartLayout.getHAxisValue(chartBounds.width + chartBounds.left),
          };
        } else {
          this.lockHAxis = false;
          this.lockVAxis = true;
          this.chartConfig.mainChartOptions["explorer"]["axis"] = "horizontal";
          this.chartConfig.mainChartOptions["vAxis"]["viewWindow"] = {
            min: 0,
            max: chartLayout.getVAxisValue(chartBounds.top),
          };
        }
      } else {
        this.lockHAxis = false;
        this.lockVAxis = false;
        this.chartConfig.mainChartOptions["explorer"]["axis"] = "horizontal";
        if (axis == "hAxis") {
          this.chartConfig.mainChartOptions["hAxis"]["viewWindow"] = {};
        } else {
          this.chartConfig.mainChartOptions["vAxis"]["viewWindow"] = { min: 0 };
        }
      }
    },
    switchChart: async function (direction, channel) {
      if (!channel) {
        channel = {
          data_levels: ["channel"],
          data_query: [{ level: "channel", value: "tv" }],
        };
      }
      this.graph_visible = false;
      const token = await this.$auth.getTokenSilently();
      let date_range = {
        start_date: this.project_date_range["start_date"],
        end_date: this.project_date_range["end_date"],
      };
      // date_range = null;
      let dashboard_data = await api_caller
        .get_dashboard_data(token, this.$project.get_val(), channel, date_range, this.data_filters)
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          } else {
            return false;
          }
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
      if (!dashboard_data) {
        this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
      } else {
        this.reported_roas = dashboard_data["roas"].toLocaleString("en-CA", {
          style: "currency",
          currency: "CAD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          notation: "compact",
        });
        this.reported_spend = dashboard_data["spend"].toLocaleString("en-CA", {
          style: "currency",
          currency: "CAD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          notation: "compact",
        });
        this.reported_cpm = dashboard_data["cpm"].toLocaleString("en-CA", {
          style: "currency",
          currency: "CAD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          notation: "compact",
        });
        this.reported_impr = dashboard_data["impressions"].toLocaleString("en-CA", {
          notation: "compact",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        this.dataset = dashboard_data["data"];
        this.response_curve_dataset = dashboard_data["response_curve"];
      }

      if (this.selected_metrics.length == 0) {
        this.select_indicator(0);

        // setup preset indicator from user profile
        if (this.user_profile && this.user_profile["preset_metrics"]) {
          this.user_profile["preset_metrics"].forEach((metric) => {
            this.select_indicator(metric);
          });
        }
      }

      this.mini_charts_list = [];
      for (let row in this.selected_metrics) {
        if (row == 0) {
          this.selected_metrics[row]["visible"] = false;
        } else {
          this.selected_metrics[row]["visible"] = !this.selected_metrics[row]["visible"];
        }
      }
      for (let row of this.selected_metrics) {
        this.update_graph(row.metric_id);
        this.graph_visible = true;
      }

      let temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      let temp_chart_headers = this.visible_graph_header;
      this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
      this.reload_graph();
    },

    onGraphSelected: function (selected) {
      this.rawData = {
        cols: chartData[selected][0].dataHeaders,
        rows: format_data_to_gchart(chartDataTest["data"], ["date", "sales_attribution"]),
      };
    },

    toggleBase: function () {
      let indicator = this.get_idx_indicator_selected(0);
      let jdx = indicator[0];
      indicator = indicator[1];
      this.selected_metrics[jdx]["visible"] = !indicator["visible"];

      let temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      let temp_chart_headers = this.visible_graph_header;
      this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
      this.chartConfig.mainChartOptions.colors = this.visible_graph_colors;
      this.chartConfig.mainChartOptions.series = this.graph_series_config;
    },

    toggleBollinger: function (idx) {
      this.showBollinger = !this.showBollinger;

      // add the new header column columns to the table header
      let temp_chart_rows = [];
      let indicator = this.get_idx_indicator_selected(idx);
      let jdx = indicator[0];
      indicator = indicator[1];

      let data = 0;

      let type = indicator["params"][0];

      if (!indicator.visible) {
        this.selected_metrics[jdx]["visible"] = true;
        if (type === "keltner") {
          data = calculate_keltner_channels(
            this.dataset,
            "sales_attribution",
            indicator["metric_stat_headers"][0],
            indicator["metric_stat_headers"][1],
            indicator["metric_stat_headers"][2]
          );
        } else {
          data = calculate_bollinger_band(
            this.dataset,
            "sales_attribution",
            indicator["metric_stat_headers"][0],
            indicator["metric_stat_headers"][1],
            indicator["metric_stat_headers"][2]
          );
        }
        this.dataset = data;
        temp_chart_rows = format_data_to_gchart(data, this.visible_columns);
      } else {
        this.selected_metrics[jdx]["visible"] = false;
        temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      }
      let temp_chart_headers = this.visible_graph_header;
      this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
      this.chartConfig.mainChartOptions.colors = this.visible_graph_colors;
      this.chartConfig.mainChartOptions.series = this.graph_series_config;
    },

    toggleMovingAvg: function (idx) {
      // this.showSevenDaysMovAvg = !this.showSevenDaysMovAvg;

      let temp_chart_rows = [];
      let indicator = this.get_idx_indicator_selected(idx);
      let jdx = indicator[0];
      indicator = indicator[1];

      let data = [];
      let type = indicator["params"][0];

      if (!indicator.visible) {
        this.selected_metrics[jdx]["visible"] = true;
        if (type === "ema") {
          let days = indicator["params"][1];
          data = calculate_exponential_moving_average(
            this.dataset,
            days,
            "sales_attribution",
            indicator["metric_stat_headers"]
          );
        } else if (type === "macd") {
          let dpoint = indicator["params"][1];
          data = calculate_MACD(this.dataset, dpoint, indicator["metric_stat_headers"]);
        } else {
          let days = indicator["params"][1];
          data = calculate_simple_moving_average(
            this.dataset,
            days,
            "sales_attribution",
            indicator["metric_stat_headers"]
          );
        }
        this.dataset = data;
        temp_chart_rows = format_data_to_gchart(data, this.visible_columns);
      } else {
        this.selected_metrics[jdx]["visible"] = false;
        temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      }
      let temp_chart_headers = this.visible_graph_header;
      this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
      this.chartConfig.mainChartOptions.colors = this.visible_graph_colors;
      this.chartConfig.mainChartOptions.series = this.graph_series_config;
    },

    toggleAvgTrueRange: function (idx) {
      // this.showSevenDaysMovAvg = !this.showSevenDaysMovAvg;

      let temp_chart_rows = [];
      let indicator = this.get_idx_indicator_selected(idx);
      let jdx = indicator[0];
      indicator = indicator[1];

      let days = indicator["params"][0];

      let data = [];

      if (!indicator.visible) {
        this.selected_metrics[jdx]["visible"] = true;
        data = calculate_average_true_range(
          this.dataset,
          days,
          "sales_attribution",
          indicator["metric_stat_headers"]
        );
        this.dataset = data;
        temp_chart_rows = format_data_to_gchart(data, this.visible_columns);
      } else {
        this.selected_metrics[jdx]["visible"] = false;
        temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      }
      let temp_chart_headers = this.visible_graph_header;
      this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
      this.chartConfig.mainChartOptions.colors = this.visible_graph_colors;
      this.chartConfig.mainChartOptions.series = this.graph_series_config;
    },

    toggleMovAvgCross: function (crossIdx) {
      let temp_chart_rows = [];
      let crossIndicator = this.get_idx_indicator_selected(crossIdx);
      let jdx = crossIndicator[0];
      crossIndicator = crossIndicator[1];
      let fast_ma = crossIndicator["params"][0];
      let slow_ma = crossIndicator["params"][1];

      if (!crossIndicator.visible) {
        this.selected_metrics[jdx]["visible"] = true;
        let data = calculate_simple_moving_average_cross(
          this.dataset,
          "sales_attribution",
          fast_ma,
          slow_ma,
          crossIndicator["metric_stat_headers"][0],
          crossIndicator["metric_stat_headers"][1],
          crossIndicator["metric_stat_headers"][2]
        );
        this.dataset = data;
        temp_chart_rows = format_data_to_gchart(data, this.visible_columns);
      } else {
        this.selected_metrics[jdx]["visible"] = false;
        temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      }
      temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      let temp_chart_headers = this.visible_graph_header;

      this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
      this.chartConfig.mainChartOptions.colors = this.visible_graph_colors;
      this.chartConfig.mainChartOptions.series = this.graph_series_config;
    },

    toggleMarkerLine: function (toggle, col) {
      let temp_chart_rows = [];
      let indicator = this.get_idx_indicator_selected(200);
      let jdx = indicator[0];
      indicator = indicator[1];

      if (toggle) {
        this.selected_metrics[jdx]["visible"] = !this.selected_metrics[jdx]["visible"];
      } else {
        let data = set_marker_line(
          this.dataset,
          "sales_attribution",
          col,
          indicator["metric_stat_headers"][1],
          indicator["metric_stat_headers"][0]
        );
        this.dataset = data;
      }
      temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      let temp_chart_headers = this.visible_graph_header;

      this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
      this.chartConfig.mainChartOptions.colors = this.visible_graph_colors;
      this.chartConfig.mainChartOptions.series = this.graph_series_config;
    },

    toggleMiniGraph: function (idx) {
      let indicator = null;
      let miniChartWithHaxisHeightPct = "75%";
      let mainChartWithHaxisHeightPct = "95%";

      indicator = this.get_idx_indicator_selected(idx);
      let jdx = indicator[0];
      indicator = indicator[1];

      if (!indicator.visible) {
        let tmp_chart_data = {};
        let headers = indicator["graph_header"];
        let chart_options = JSON.parse(JSON.stringify(this.chartConfig.miniChartOptions));

        if (idx == 100) {
          chart_options["vAxis"]["scaleType"] = "log";
        }

        // for roas indicator
        if (idx == 100) {
          tmp_chart_data = {
            cols: headers,
            rows: format_data_to_gchart(this.dataset, ["date", "roas"]),
          };
          chart_options["seriesType"] = "lines";
          chart_options["colors"] = ["#50A684"]
        } else if (idx == 101) {
          this.dataset = compute_minichart_volume(
            this.dataset,
            "spend",
            "spend_delta_volume",
            "spend_normalized"
          );
          headers.push({
            id: "bar_color",
            name: "Bar Color",
            role: "style",
            type: "string",
          });

          let rows = format_data_to_gchart(this.dataset, ["date", "spend"]);
          rows.forEach((row, idx) => {
            row["c"].push({
              f: null,
              v: this.dataset[idx]["spend_delta_volume"] >= 0 ? "green" : "red",
            });
          });

          tmp_chart_data = {
            cols: headers,
            rows: rows,
          };
        } else if (idx == 102) {
          this.dataset = compute_minichart_volume(
            this.dataset,
            "impressions",
            "impressions_delta_volume",
            "impressions_normalized"
          );

          headers.push({
            id: "bar_color",
            name: "Bar Color",
            role: "style",
            type: "string",
          });

          let rows = format_data_to_gchart(this.dataset, ["date", "impressions"]);
          rows.forEach((row, idx) => {
            row["c"].push({
              f: null,
              v: this.dataset[idx]["impressions_delta_volume"] >= 0 ? "green" : "red",
            });
          });

          tmp_chart_data = {
            cols: headers,
            rows: rows,
          };
        } else if (idx == 103) {
          tmp_chart_data = {
            cols: headers,
            rows: format_data_to_gchart(this.dataset, ["date", "cpm"]),
          };
          chart_options["seriesType"] = "lines";
          chart_options["colors"] = ["#426E86"];
        }
        this.selected_metrics[jdx]["visible"] = true;

        // when adding new minigraph hide the axi for the main graph
        // And if there are more than 1 mini graph, add the xAxis to the last mini graph
        this.chartConfig.mainChartOptions["hAxis"]["textPosition"] = "none";
        this.chartConfig.mainChartOptions["chartArea"]["height"] = "100%";
        if (this.mini_charts_list.length > 0) {
          this.mini_charts_list[this.mini_charts_list.length - 1]["config"]["hAxis"][
            "textPosition"
          ] = "none";
          this.mini_charts_list[this.mini_charts_list.length - 1]["config"]["chartArea"][
            "height"
          ] = "100%";
        }
        chart_options["chartArea"]["height"] = miniChartWithHaxisHeightPct;
        chart_options["title"] = this.selected_metrics[jdx]["metric_name"];
        chart_options["titlePosition"] = "in";
        this.mini_charts_loc[idx] = this.mini_charts_list.length;

        this.mini_charts_list.push({
          graph_id: "miniChart_" + this.mini_charts_list.length + 1,
          data: tmp_chart_data,
          config: chart_options,
        });
      } else {
        this.selected_metrics[jdx]["visible"] = false;
        let chart_loc = this.mini_charts_loc[idx];
        this.mini_charts_list.splice(chart_loc, 1);

        if (this.mini_charts_list.length > 0) {
          delete this.mini_charts_list[this.mini_charts_list.length - 1]["config"][
            "hAxis"
          ]["textPosition"];
          this.mini_charts_list[this.mini_charts_list.length - 1]["config"]["chartArea"][
            "height"
          ] = miniChartWithHaxisHeightPct;
        } else {
          delete this.chartConfig.mainChartOptions["hAxis"]["textPosition"];
          this.chartConfig.mainChartOptions["chartArea"][
            "height"
          ] = mainChartWithHaxisHeightPct;
        }

        for (let minichartidx of Object.keys(this.mini_charts_loc)) {
          if (minichartidx != idx && this.mini_charts_loc[minichartidx] > chart_loc) {
            this.mini_charts_loc[minichartidx] -= 1;
          }
        }
      }
      this.miniChartHeight = 150;
      this.mainChartHeight =
        this.screenHeight - this.mini_charts_list.length * this.miniChartHeight;
    },

    // toggleCumulative: function () {
    //   let temp_chart_headers = JSON.parse(JSON.stringify(this.currChart.dataHeaders));
    //   let temp_chart_rows = JSON.parse(JSON.stringify(this.currChart.dataRows));

    //   if (this.cumulative) {
    //     for (let index = 1; index < temp_chart_rows.length; index++) {
    //       temp_chart_rows[index]["c"][1]["v"] += temp_chart_rows[index - 1]["c"][1]["v"];
    //     }
    //   }

    //   // reset off all metrics
    //   this.showBollinger = false;
    //   this.showSevenDaysMovAvg = false;
    //   this.showFourteenDaysMovAvg = false;
    //   this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
    // },

    select_indicator: function (idx) {
      let indicator = this.get_idx_indicator_available(idx);
      let jdx = indicator[0];
      indicator = indicator[1];
      this.available_metrics.splice(jdx, 1);
      this.selected_metrics.push(indicator);
      this.selected_metric_ids.push(indicator.metric_id);
      this.update_graph(idx);
    },

    remove_indicator: function (idx) {
      // remove the minigraph in case of roas
      if (idx == 6) {
        if (this.mini_graph_visible) {
          this.toggleMiniGraph(6);
        }
      }

      let indicator = this.get_idx_indicator_selected(idx);
      let jdx = indicator[0];
      indicator = indicator[1];
      if (indicator["visible"]) {
        this.update_graph(indicator["metric_id"]);
      }
      this.selected_metrics.splice(jdx, 1);
      this.selected_metric_ids.splice(jdx, 1);
      this.available_metrics.push(indicator);
      this.reload_graph();
    },

    update_graph: function (idx) {
      switch (true) {
        case idx == 0:
          this.toggleBase();
          break;
        case [1, 2, 6, 7, 8, 9, 10, 11, 12, 13, 17, 18].includes(idx):
          this.toggleMovingAvg(idx);
          break;
        case [4, 5, 15].includes(idx):
          this.toggleMovAvgCross(idx);
          break;
        case [16].includes(idx):
          this.toggleAvgTrueRange(idx);
          break;
        case [3, 14].includes(idx):
          this.toggleBollinger(idx);
          break;
        case idx == 200:
          this.toggleMarkerLine(true);
          break;
        case idx >= 100 && idx < 200:
          this.toggleMiniGraph(idx);
          break;
      }
      this.reload_graph();
    },

    get_idx_indicator_selected: function (idx) {
      let jdx = 0;
      for (let indicator of this.selected_metrics) {
        if (indicator["metric_id"] == idx) {
          return [jdx, indicator];
        }
        jdx += 1;
      }
      return -1;
    },

    get_idx_indicator_available: function (idx) {
      let jdx = 0;
      for (let indicator of this.available_metrics) {
        if (indicator["metric_id"] == idx) {
          return [jdx, indicator];
        }
        jdx += 1;
      }
    },

    get_chart_coords: function (chart) {
      var chartLayout = chart.getChartLayoutInterface();
      var chartBounds = chartLayout.getChartAreaBoundingBox();

      return {
        x: {
          min: chartLayout.getHAxisValue(chartBounds.left),
          max: chartLayout.getHAxisValue(chartBounds.width + chartBounds.left),
        },
        y: {
          min: chartLayout.getVAxisValue(chartBounds.top),
          max: chartLayout.getVAxisValue(chartBounds.height + chartBounds.top),
        },
      };
    },

    set_graph_view_window: function (isMainChartChanged, min, max) {
      if (isMainChartChanged) {
        if (this.mini_charts_list.length > 0) {
          this.mini_charts_list.forEach((chart) => {
            chart.config["hAxis"]["viewWindow"] = { min: min, max: max };
          });
        }
      }
    },

    reload_graph: function () {
      this.graph_visible = false;
      setTimeout(() => (this.graph_visible = true), 10);
    },

    open_fullscreen_chart: function () {
      this.fullScreenChartConfig = { ...this.chartConfig };
      this.fullScreenChartOverlay = true;
    },
    deepEqual: function (object1, object2) {
      if (object1 == null && object2 != null) {
        return false;
      }
      if (object1 != null && object2 == null) {
        return false;
      }
      if (object1 == null && object2 == null) {
        return true;
      }
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = this.isObject(val1) && this.isObject(val2);
        if (
          (areObjects && !this.deepEqual(val1, val2)) ||
          (!areObjects && val1 !== val2)
        ) {
          return false;
        }
      }

      return true;
    },
    isObject: function (object) {
      return object != null && typeof object === "object";
    },
    updateLevelVal: function (indexShift) {
      let curIdx =
        this.available_level_vals.indexOf(this.selected_level_val) + indexShift;
      this.selected_level_val = this.available_level_vals[curIdx];
    },
  },
  data: () => ({
    start_date: null,
    end_date: null,
    selected_level_val: null,
    available_level_vals: [],
    currChartIndex: 0,
    currChart: { title: "TV" },
    raw_dataset: chartDataTest["data"],
    dataset: [],
    core_dataset_response_var: "sales_attribution",
    rawData: {},
    user_profile: user_setting_test,
    miniGraphData: {},
    fullScreenChartConfig: {},
    chartConfig: chartConfig,
    chartData: null,
    settingOverlay: false,
    fullScreenChartOverlay: false,
    showBollinger: false,
    showSevenDaysMovAvg: false,
    showFourteenDaysMovAvg: false,
    selectedGraph: { label: "Spends", value: "spends" },
    metricIdxMapping: {},
    graph_visible: false,
    mini_graph_visible: false,

    indicators_drawer: false,
    finance_drawer: true,

    response_curve_dataset: null,
    response_curve_config: responseChartConfig,

    selected_metrics: [],
    selected_metric_ids: [],
    available_metrics: [],
    screenHeight: window.innerHeight * (window.innerHeight > 1000 ? 0.6 : 0.55),
    fullScreenHeight: window.innerHeight * 0.95 - 24,
    miniChartHeight: 125,
    mainChartHeight: 0,
    mainChartKey: 0,
    miniChartKey: 0,

    mini_charts_list: [],
    mini_charts_loc: {},
    tooltipMouseOverRow: null,

    cumulative: false,
    chartEvents: {},

    overlay_indicators_visible: true,
    overlay_indicators_expanded: false,

    lockVAxis: false,
    lockHAxis: false,

    lastMainChartHeight: 0,

    reported_roas: "-",
    reported_spend: "-",
    reported_impr: "-",
    reported_synergy: "-",
    reported_cpm: "-",
  }),
  mounted() {
    this.mainChartHeight =
      this.screenHeight * 0.97 - this.mini_charts_list.length * this.miniChartHeight;
    (this.chartEvents = {
      onmouseover: (e) => {
        this.tooltipMouseOverRow = e.row;
      },
      select: () => {
        if (this.get_idx_indicator_selected(200) == -1) {
          this.select_indicator(200);
        }
        let indicator = this.get_idx_indicator_selected(200);
        let jdx = indicator[0];
        this.selected_metrics[jdx]["visible"] = true;
        const chart = this.$refs.fullScreenMainChart
          ? this.$refs.fullScreenMainChart.chartObject
          : this.$refs.mainChart.chartObject;
        const markerCol = chart.getSelection()[0]["row"];
        this.toggleMarkerLine(null, markerCol);
        this.reload_graph();
      },
      ready: () => {
        // set up Mutation observer event for main chart to listen to zoom event
        let mainChartContainer = document.getElementById("mainChart");
        const chart = this.$refs.mainChart.chartObject;
        let zoomLast = this.get_chart_coords(chart);
        let mainChartObserver = new MutationObserver(() => {
          if (!this.lockHAxis) {
            let zoomCurrent = this.get_chart_coords(chart);
            if (JSON.stringify(zoomLast) !== JSON.stringify(zoomCurrent)) {
              zoomLast = this.get_chart_coords(chart);
              this.set_graph_view_window(
                true,
                zoomLast["x"]["min"],
                zoomLast["x"]["max"]
              );
            }
          }
        });
        mainChartObserver.observe(mainChartContainer, {
          childList: true,
          subtree: true,
        });

        let fullMainChartContainer = document.getElementById("fullScreenMainChart");
        if (fullMainChartContainer) {
          const fullScreenChart = this.$refs.fullScreenMainChart.chartObject;
          let zoomLast = this.get_chart_coords(fullScreenChart);
          let fullScreenMainChartObserver = new MutationObserver(() => {
            let zoomCurrent = this.get_chart_coords(fullScreenChart);
            if (JSON.stringify(zoomLast) !== JSON.stringify(zoomCurrent)) {
              zoomLast = this.get_chart_coords(fullScreenChart);
              this.set_graph_view_window(
                true,
                zoomLast["x"]["min"],
                zoomLast["x"]["max"]
              );
            }
          });
          fullScreenMainChartObserver.observe(fullMainChartContainer, {
            childList: true,
            subtree: true,
          });
        }

        let mainChartDiv = document.getElementById("mainChart");
        this.lastMainChartHeight = mainChartDiv.offsetHeight;

        let handleResize = () => {
          let currentHeight = mainChartDiv.offsetHeight;
          if (this.lastMainChartHeight !== currentHeight && currentHeight !== 0) {
            this.lastMainChartHeight = currentHeight;
            this.mainChartHeight = currentHeight;
            this.mainChartKey++;

            this.miniChartHeight =
              (this.screenHeight - this.mainChartHeight) / this.mini_charts_list.length;
            this.miniChartKey++;
          }
        };

        let debounce_func = lodash.debounce(handleResize, 500);

        let resizeObserver = new ResizeObserver(() => {
          debounce_func();
        });
        resizeObserver.observe(mainChartDiv);
      },
    }),
      (this.available_metrics = [...indicator_list["indicators"]]);

    this.start_date = this.project_date_range["start_date"];
    this.end_date = this.project_date_range["end_date"];
    this.available_level_vals = this.data_levels["level_values"];
    if (!this.available_level_vals.includes(this.selected_level_val)) {
      this.selected_level_val = this.available_level_vals[0];
    }

    // this.switchChart(null, this.selected_channel);

    // (filter_dataset(chartDataTest["data"], "channel", ["TV"]));
    // this.currChartIndex = this.selected_channel ? CHANNEL_LIST.indexOf(this.selected_channel) : this.currChartIndex;
    // this.currChart = this.selected_channel ? { title: this.selected_channel } : this.currChart;
    // (this.rawData = {
    //   cols: this.visible_graph_header,
    //   rows: format_data_to_gchart(this.dataset, ["date"])
    // }),

    // this.select_indicator(0);
    // this.select_indicator(1);
    // this.select_indicator(2);
    // this.select_indicator(3);
    // this.select_indicator(100);
    // this.update_graph(1);
    // this.update_graph(2);
    // this.update_graph(3);
    // this.update_graph(100);
  },
  watch: {
    selected_channel: {
      immediate: true,
      handler: function () {
        let channel_title = "";
        if (this.selected_channel != null) {
          for (let q of this.selected_channel["data_query"]) {
            channel_title += q["value"];
            channel_title += "__";
          }
          channel_title = channel_title.slice(0, -2);
          this.selected_level_val = channel_title;
        }
      },
      deep: true,
    },
    selected_level_val: {
      immediate: true,
      handler: function () {
        let channel = {
          data_levels: [],
          data_query: [],
        };
        let i = 0;
        if (this.selected_level_val != null) {
          for (let v of this.selected_level_val.split("__")) {
            channel["data_levels"].push(this.data_levels["selected_levels"][i]);
            channel["data_query"].push({
              level: this.data_levels["selected_levels"][i],
              value: v,
            });
            i++;
          }
          this.switchChart(null, channel);
        }
      },
      deep: true,
    },
    data_levels: {
      handler: function () {
        this.available_level_vals = this.data_levels["level_values"];
        if (!this.available_level_vals.includes(this.selected_level_val)) {
          this.selected_level_val = this.available_level_vals[0];
        }
      },
      deep: true,
    },
    project_date_range: {
      immediate: false,
      handler: function () {
        this.start_date = this.project_date_range["start_date"];
        this.end_date = this.project_date_range["end_date"];
        this.updateLevelVal(1)
      },
      deep: true,
    },
  },
  computed: {
    visible_columns: function () {
      let visible = ["date"];
      for (let stat of this.selected_metrics) {
        if (stat["metric_id"] >= 100 && stat["metric_id"] < 200) {
          continue;
        }
        if (stat["visible"] == true && Array.isArray(stat["metric_stat_headers"])) {
          for (let h of stat["metric_stat_headers"]) {
            visible.push(h);
          }
        } else if (stat["visible"] == true) {
          visible.push(stat["metric_stat_headers"]);
        }
      }
      return visible;
    },
    visible_graph_header: function () {
      let visible_header = [
        {
          id: "date",
          name: "Date",
          role: "domain",
          type: "date",
        },
      ];
      for (let stat of this.selected_metrics) {
        if (stat["metric_id"] >= 100 && stat["metric_id"] < 200) {
          continue;
        }
        if (stat["visible"] == true && Array.isArray(stat["graph_header"])) {
          for (let h of stat["graph_header"]) {
            visible_header.push(h);
          }
        } else if (stat["visible"] == true) {
          visible_header.push(stat["graph_header"]);
        }
      }
      if (visible_header.length < 2) {
        visible_header.push({
          id: "blank",
          name: "Blank",
          role: "data",
          type: "number",
        });
      }
      return visible_header;
    },
    visible_graph_colors: function () {
      let visible_colors = [];

      if (this.visible_columns.length < 2) {
        visible_colors.push("white");
      }

      for (let stat of this.selected_metrics) {
        console.log(stat)
        if (stat["metric_id"] >= 100 && stat["metric_id"] < 200) {
          continue;
        } else if (stat["visible"] == true && Array.isArray(stat["graph_header"])) {
          // skip all non data role column
          // this is the resolve the graph color shifting behavior
          console.log(stat["graph_header"])
          stat["graph_header"].forEach((item, idx) => {
            if (item.role == "data") {
              visible_colors.push(stat["graph_color"][idx]);
            }
          });
        } else if (stat["visible"] == true) {
          visible_colors.push(stat["graph_color"]);
        }
      }
      console.log(visible_colors)
      return visible_colors;
    },
    graph_series_config: function () {
      let graph_series = {};
      let series_idx = 0;
      for (let stat of this.selected_metrics) {
        if (stat["metric_id"] >= 100 && stat["metric_id"] < 200) {
          continue;
        }
        if (stat["visible"] == true && Array.isArray(stat["graph_series_config"])) {
          for (let h of stat["graph_series_config"]) {
            graph_series[series_idx] = h;
            series_idx++;
          }
        } else if (stat["visible"] == true) {
          if (Array.isArray(stat["graph_header"])) {
            // skip all non data role column
            // this is the resolve the graph color shifting behavior
            let dataRoleHeader = stat["graph_header"].filter(
              (header) => header.role == "data"
            );
            series_idx += dataRoleHeader.length;
          } else {
            series_idx++;
          }
        }
      }
      return graph_series;
    },
    comparisonDropdownOpts: function () {
      let chartOptions = [];
      this.chartData.forEach((chart, idx) => {
        chartOptions.push({ name: chart.title, index: idx });
      });
      return chartOptions;
    },
    graphDropdownOpts: function () {
      return [
        { label: "Spends", value: "spends" },
        { label: "Impressions", value: "impressions" },
        { label: "Contributions", value: "contributions" },
        { label: "ROAS", value: "roas" },
      ];
    },

    indicator_list_breakdown: function () {
      let indicator_lst = [
        {
          metric_id: "Data",
          metric_name: "Data",
          children: [],
        },
        {
          metric_id: "Moving Averages",
          metric_name: "Moving Averages",
          children: [
            {
              metric_id: "SMA",
              metric_name: "SMA",
              children: [],
            },
            {
              metric_id: "EMA",
              metric_name: "EMA",
              children: [],
            },
          ],
        },
        {
          metric_id: "Volatility Metrics",
          metric_name: "Volatility Metrics",
          children: [],
        },
        {
          metric_id: "Analysis",
          metric_name: "Analysis",
          children: [],
        },
        {
          metric_id: "Other",
          metric_name: "Other",
          children: [],
        },
      ];
      for (let indicator of indicator_list["indicators"]) {
        if (
          !Object.keys(indicator).includes("organization") ||
          indicator.organization.length == 0
        ) {
          indicator_lst[4]["children"].push({ ...indicator });
        } else if (indicator.organization.length == 1) {
          indicator_lst[indicator.organization[0]]["children"].push({ ...indicator });
        } else if (indicator.organization.length == 2) {
          indicator_lst[indicator.organization[0]]["children"][indicator.organization[1]][
            "children"
          ].push({ ...indicator });
        }
      }
      // for(let indicator of this.available_metrics){
      //   if(! Object.keys(indicator).includes('organization') || indicator.organization.length == 0){
      //       indicator_lst[4]['children'].push(indicator);
      //   }
      //   else if(indicator.organization.length == 1){
      //     indicator_lst[indicator.organization[0]]['children'].push(indicator);
      //   }
      //   else if(indicator.organization.length == 2){
      //     indicator_lst[indicator.organization[0]]['children'][indicator.organization[1]]['children'].push(indicator);
      //   }
      // }
      return indicator_lst;
    },
  },
};
</script>

<style scoped>
.performanceChart {
  height: 100%;
  width: 100%;
}
::v-deep .v-select__selection.v-select__selection--comma {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 1.5em !important;
  line-height: 30px;
}
::v-deep .v-list-item__title {
  font-family: "Poppins" !important;
}

.chartHeaderText {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 1.5em;
}

.reported_roas_title {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 1.5em !important;
  line-height: 30px;
}

.toolBar {
  float: right;
}

.toolButton {
  padding: 0 32px;
  width: 50px;
  color: black;
}

::v-deep .v-overlay__content {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  top: 0;
  /* 
  width: 30%;
  right: 0;
  */
}

.overlayField {
  padding: 0 16px;
}

.overlayButton {
  float: right;
}

.chartWithOverlay {
  /* position: relative; */
  width: 100%;
  /* height: 500px; */
}
.resizableChart {
  resize: vertical;
  overflow: hidden;
  border-bottom: 1px solid;
  max-height: 75%;
}
.miniChartWithOverlay {
  /* position: relative; */
  width: 100%;
}
.metricLockOverlayVertical {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 10px;
  left: 25px;
}
.metricLockOverlayHorizontal {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 400px;
  left: 100%;
}
.metricToggleOverlay {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 30px;
  left: 70px;
  max-height: 450px;
  overflow-y: auto;
}
.metricToggleOverlayOverlay {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 100px;
  left: 70px;
}
.metricLockOverlayOverlay {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 10px;
  left: 40px;
}

.metricToggleRow {
  margin-left: 0;
  margin-right: auto;
}
.metric_toolbar_title {
  font-weight: 600 !important;
  font-family: "Poppins";
}
.metric_toolbar_subtitle {
  font-weight: 600 !important;
  font-family: "Poppins";
}
.metric_toolbar_metric_title {
  line-height: 1em;
  font-family: "Poppins";
  font-weight: 400 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: calc(80%);
  text-align: left;
  overflow: hidden;
}

.graph_select_title {
  line-height: 1.5em;
  font-family: "Poppins";
  font-weight: 600 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: calc(80%);
  text-align: left;
  overflow: hidden;
}
.graph_select_options {
  line-height: 1em;
  font-family: "Poppins";
  font-weight: 400 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: calc(100%);
  text-align: left;
  overflow: hidden;
}

.metric_overlay_title {
  line-height: 1.1em;
  font-family: "Poppins";
  font-weight: 600 !important;
  color: black;
}
.metric_overlay_metric_title {
  line-height: 1em;
  font-family: "Poppins";
  font-weight: 400 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 200px;
  text-align: left;
  overflow: hidden;
  color: black;
}
.metric_overlay_metric_stat {
  line-height: 1em;
  font-family: "Poppins";
  font-weight: 400 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 70px;
  text-align: right;
  overflow: hidden;
}

.metric_selection_title {
  line-height: 1.1em;
  font-family: "Poppins";
  font-weight: 600 !important;
}
.metric_selection_metric_title {
  line-height: 1em;
  font-family: "Poppins";
  font-weight: 400 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100px;
  text-align: left;
  overflow: hidden;
  color: black;
}

.tooltip_description {
  font-family: "Poppins" !important;
  font-weight: 200;
}
.long_description {
  font-family: "Poppins" !important;
}

.expansionPanel {
  overflow: none;
}

.chart_finance_metric_title {
  float: left;
  margin-left: 10px;
  font-family: "Poppins";
  font-weight: 600;
}
.chart_finance_metric {
  float: right;
  margin-right: 10px;
  font-family: "Poppins";
  font-weight: 400;
}
</style>
