<template>
    <v-card style="max-height: 100%; overflow-y: auto" class="ma-0 pa-0">
        <v-row class="ma-0 pa-0" style="width: 100%" justify="center">
        <v-col cols="auto">
          <span class="report-subtitle">Format Response Curves</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" class="ma-0 pa-0">
          <v-divider class="mx-12"></v-divider>
        </v-col>
        <v-col :cols="response_curve_col_size" class="ma-0 pa-0">
          <v-row class="ma-0 pa-0" justify="center">
            <v-col
              cols="3"
              v-for="(value, index) in dataset"
              :key="index"
            > 
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                  <span class="graph-title">{{ value['level_value'].replaceAll('__',' - ').replaceAll('_',' ') }}</span>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0" style="height: 300px">
                  <GChart
                    style="height: 300px"
                    type="LineChart"
                    :data="value['data']"
                    :options="getChartConfig(index)"
                    :resizeDebounce="50"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" v-if="response_curve_comments">
          <v-row class="ma-0 pa-0">
            <v-col cols="12">
              <v-row class="ma-0 pa-0">
                <v-spacer></v-spacer>
                <v-col cols="1">
                  <v-btn
                    icon
                    style="height: 25px; width: 25px"
                    @click="
                      response_curve_comments = false;
                      response_curve_col_size = 12;
                    "
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12"> </v-col>
            <v-col cols="12">
              <v-textarea
                clearable
                clear-icon="mdi-close-circle"
                label="Text"
                value="This is clearable text."
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </template>
  
  <script>
  import { GChart } from "vue-google-charts/legacy";
  
  import responseCurveConfig from "@/configs/Performance/performance_response_curve_config.json";
  import cpalette from "@/configs/ChartColors/get_color_palette.js";

  export default {
    name: "ResponseCurves",
    props: [
      'dataset',
      'color_palette'
    ],
    components: {
      GChart,
    },
    methods: {
      reload_graph: function () {
        this.graph_visible = false;
        setTimeout(() => (this.graph_visible = true), 10);
      },
  
      onCommentSubmit: function (section) {
        if (this.report_comments[section]) {
          let newCommentContent = null
          
          switch(section){
            case 'spend_impr_contribution_overview':
              newCommentContent = this.new_spend_impr_contribution_overview_comment
              this.new_spend_impr_contribution_overview_comment = null;
              break;
            case 'spend_impr_contribution_environmental':
              newCommentContent = this.new_spend_impr_contribution_environmental_comment
              this.new_spend_impr_contribution_environmental_comment = null;
              break;
            case 'roi_contribution':
              newCommentContent = this.new_roi_contribution_comment
              this.new_roi_contribution_comment = null;
              break;
          }
  
          if (newCommentContent) {
            let newComment = {
              name: this.$user_data["first_name"] + " " + this.$user_data["last_name"],
              content: newCommentContent,
              timestamp: new Date().toLocaleString()
            };
            this.report_comments[section].push(newComment);
          }
        }
      },
  
      getUserInitial: function (name) {
        let initial = "";
        if (name) {
          let splitted = name.split(" ");
          initial = splitted[0].substring(0, 1).toUpperCase();
  
          if (splitted.length > 1) {
            initial += splitted[splitted.length - 1].substring(0, 1).toUpperCase();
          }
        }
        return initial;
      },

      getChartConfig: function(idx){
        let config = {...this.response_curve_config}
        config['colors'] = [this.chart_colors[idx]];
        return config;
      }
    },
  
    data: () => ({
      response_curve_config: responseCurveConfig,
      response_curve_data: [],
      response_curve_col_size: 12,
      response_curve_comments: false,
      chart_colors: []
    }),
    mounted() {
      let colors = cpalette.get_color_palette(this.color_palette);
      this.chart_colors = colors;

    },
    watch: {},
    computed: {

    },
  };
  </script>
  
  <style scoped>
  .main-page-title {
    /* line-height: 5em; */
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1.5em;
  }
  .report-subtitle {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 1.5em;
  }
  
  .graph-title {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 0.9em;
  }
  
  .report-version {
    font-family: "Poppins";
    font-weight: 600;
    align-items: center;
    /* font-size: 0.5em; */
    /* width: 50px; */
  }
  
  .performanceChart {
    height: 100%;
    width: 100%;
  }
  
  .chartHeader {
    .chartHeaderText {
      font-family: "Poppins" !important;
      font-weight: 600;
      font-size: 1.5em;
    }
  
    display: flex;
  }
  
  .toolBar {
    float: right;
  }
  
  .toolButton {
    padding: 0 32px;
    width: 50px;
    color: black;
  }
  
  ::v-deep .v-overlay__content {
    position: absolute;
    right: 0;
    height: 100%;
    width: 30%;
  }
  
  .overlayField {
    padding: 0 16px;
  }
  
  .overlayButton {
    float: right;
  }
  
  .chartWithOverlay {
    /* position: relative; */
    width: 100%;
    /* height: 500px; */
  }
  .miniChartWithOverlay {
    /* position: relative; */
    width: 100%;
    height: 75px;
  }
  
  .metricToggleOverlay {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 12%;
    left: 90px;
  }
  
  .metricToggleRow {
    margin-left: 0;
    margin-right: auto;
  }
  .metric_toolbar_title {
    font-weight: 800 !important;
  }
  .metric_toolbar_subtitle {
    font-weight: 600 !important;
  }
  .metric_toolbar_metric_title {
    line-height: 1em;
    font-weight: 200 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: calc(80%);
    text-align: left;
    overflow: hidden;
  }
  
  .tooltip_description {
    font-family: "Poppins" !important;
    font-weight: 200;
  }
  .long_description {
    font-family: "Poppins" !important;
  }
  
  .expansionPanel {
    overflow: none;
  }
  
  .reportCommentContent {
    max-height: 350px;
    overflow-y: auto;
  }
  
  .reportCommentInputField {
    float: right;
    width: 90%;
  }
  </style>
  