<template>
    <v-row class="ma-0 pa-0" align="center" justify="center">
        <v-col cols="12" class="ma-0 pa-1"  align="center" justify="center">
            <span class="statistic_value_text">
                {{ value == null ? statistic_value : format_spend(value) }} 
                <v-icon v-if="change > 0" color="green">mdi-arrow-up</v-icon>
                <v-icon v-else-if="change < 0" color="red">mdi-arrow-down</v-icon>
                <v-icon v-else-if="change == 0" color="black">mdi-minus</v-icon>
            </span>
            <span v-if="change > 0" style="color: green;">
                {{ format_percentage(change) }}
            </span>
            <span v-else-if="change < 0" style="color: red;">
                {{ format_percentage(change) }}
            </span>
        </v-col>
        <v-col cols="12" class="ma-0 pa-1" align="center">
            <span class="statistic_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'PredictionStat',
        props: [
            'statistic_title_name',
            'value',
            'change'
        ],
        data: () => ({
            statistic_value: '-',
            statistic_name: 'Statistic',
        }),
        methods: {
            format_percentage: function(stat){
                if(stat == 0 || stat === '-'){
                    return "-"
                }
                return (stat * 100).toLocaleString("en-CA",{minimumFractionDigits: 1, maximumFractionDigits: 1, notation: 'compact'}) + "%"
            },
            format_spend: function(stat){
                if(stat == 0 || stat === '-'){
                    return "-"
                }
                return stat.toLocaleString("en-CA",{style: "currency", currency: "CAD",  minimumFractionDigits: 1, maximumFractionDigits: 2, notation: 'compact'})
            }
        }
    }
</script>

<style scoped>
    .statistic_value_text{
        font-family: "Amiko";
        font-size: 20pt;
    }
    .statistic_title{
        font-family: "Amiko";
        font-size: 8pt;
    }
</style>