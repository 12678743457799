<template>
    <v-card style="height: 100%; max-height: 100%; overflow-y: auto" class="ma-0 pa-0" flat>
      <v-row class="ma-0 pa-0" style="width: 100%;">
        <v-col cols="12" class="px-12" v-if="!graph_visible">
            <v-progress-linear
              size="100"
              indeterminate
              color="primary"
            ></v-progress-linear>
        </v-col>
        <v-col v-if="graph_visible" :cols="spend_impr_contribution_col_size" class="ma-0 pa-0" style="height: calc(100% - 10px);">
          <v-row class="ma-0 pa-0" justify="space-around">
            <v-col cols="12" class="ma-0 py-0">
              <v-row class="ma-0 pa-0">
                <v-spacer></v-spacer>
                <v-col cols="5"></v-col>
                <v-spacer></v-spacer>
                <v-col cols="3" class="ma-0 pa-0 px-0" style="display: flex; justify-content: center; align-items: center;">
                  <span class="compHeader">QoQ</span>
                </v-col>
                <v-col cols="3" class="ma-0 pa-0 px-0"  style="display: flex; justify-content: center; align-items: center;" v-if="graph_data['yoy_spend'] !== '-' && graph_data['yoy_spend'] != 0">
                  <span class="compHeader">YoY</span>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="ma-0 pa-0">
                <v-spacer></v-spacer>
                <v-col cols="5" class="ma-0 pa-0">
                  <v-row class="ma-0 pa-0">
                    <v-col class="ma-0 pa-0" cols="12" style="display: flex; justify-content: center">
                      <span class="compMainStat">{{ format_spend(graph_data['spend']) }}</span>
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="12" style="display: flex; justify-content: center">
                      <span class="compMainTitle">Spend</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="3" class="ma-0 pa-0 px-0" style="display: flex; justify-content: center; align-items: center;">
                  <span class="compPercentage" v-if="graph_data['qoq_spend'] === '-' || graph_data['qoq_spend'] == 0">{{ format_percentage(graph_data['qoq_spend']) }}</span>
                  <span class="compPercentage" style="color: red" v-else-if="graph_data['qoq_spend'] < 0">{{ format_percentage(graph_data['qoq_spend']) }}</span>
                  <span class="compPercentage" style="color: green" v-else>{{ format_percentage(graph_data['qoq_spend']) }}</span>
                </v-col>
                <v-col cols="3" class="ma-0 pa-0 px-0"  style="display: flex; justify-content: center; align-items: center;" v-if="graph_data['yoy_spend'] !== '-' && graph_data['yoy_spend'] != 0">
                  <span class="compPercentage" v-if="graph_data['yoy_spend'] === '-' || graph_data['yoy_spend'] == 0">{{ format_percentage(graph_data['yoy_spend']) }}</span>
                  <span class="compPercentage" style="color: red" v-else-if="graph_data['yoy_spend'] < 0">{{ format_percentage(graph_data['yoy_spend']) }}</span>
                  <span class="compPercentage" style="color: green" v-else>{{ format_percentage(graph_data['yoy_spend']) }}</span>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="ma-0 pa-0">
                <v-spacer></v-spacer>
                <v-col cols="5" class="ma-0 pa-0">
                  <v-row class="ma-0 pa-0">
                    <v-col class="ma-0 pa-0" cols="12" style="display: flex; justify-content: center">
                      <span class="compMainStat">{{ format_spend(graph_data['roas']) }}</span>
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="12" style="display: flex; justify-content: center">
                      <span class="compMainTitle">ROAS</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="3" class="ma-0 pa-0 px-0" style="display: flex; justify-content: center; align-items: center;">
                  <span class="compPercentage" v-if="graph_data['qoq_roas'] === '-' || graph_data['qoq_roas'] == 0">{{ format_percentage(graph_data['qoq_roas']) }}</span>
                  <span class="compPercentage" style="color: red" v-else-if="graph_data['qoq_roas'] < 0">{{ format_percentage(graph_data['qoq_roas']) }}</span>
                  <span class="compPercentage" style="color: green" v-else>{{ format_percentage(graph_data['qoq_roas']) }}</span>
                </v-col>
                <v-col cols="3" class="ma-0 pa-0 px-0" style="display: flex; justify-content: center; align-items: center;" v-if="graph_data['yoy_spend'] !== '-' && graph_data['yoy_spend'] != 0">
                  <span class="compPercentage" v-if="graph_data['yoy_roas'] === '-' || graph_data['yoy_roas'] == 0">{{ format_percentage(graph_data['yoy_roas']) }}</span>
                  <span class="compPercentage" style="color: red" v-else-if="graph_data['yoy_roas'] < 0">{{ format_percentage(graph_data['yoy_roas']) }}</span>
                  <span class="compPercentage" style="color: green" v-else>{{ format_percentage(graph_data['yoy_roas']) }}</span>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="ma-0 pa-0">
                <v-spacer></v-spacer>
                <v-col cols="5" class="ma-0 pa-0">
                  <v-row class="ma-0 pa-0">
                    <v-col class="ma-0 pa-0" cols="12" style="display: flex; justify-content: center">
                      <span class="compMainStat">{{ format_spend(graph_data['sales_attribution']) }}</span>
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="12" style="display: flex; justify-content: center">
                      <span class="compMainTitle">Attribution</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="3" class="ma-0 pa-0 px-0" style="display: flex; justify-content: center; align-items: center;">
                  <span class="compPercentage" v-if="graph_data['qoq_sales_attribution'] === '-' || graph_data['qoq_sales_attribution'] == 0">{{ format_percentage(graph_data['qoq_sales_attribution']) }}</span>
                  <span class="compPercentage" style="color: red" v-else-if="graph_data['qoq_sales_attribution'] < 0">{{ format_percentage(graph_data['qoq_sales_attribution']) }}</span>
                  <span class="compPercentage" style="color: green" v-else>{{ format_percentage(graph_data['qoq_sales_attribution']) }}</span>
                </v-col>
                <v-col cols="3" class="ma-0 pa-0 px-0" style="display: flex; justify-content: center; align-items: center;" v-if="graph_data['yoy_spend'] !== '-' && graph_data['yoy_spend'] != 0">
                  <span class="compPercentage" v-if="graph_data['yoy_sales_attribution'] === '-' || graph_data['yoy_sales_attribution'] == 0">{{ format_percentage(graph_data['yoy_sales_attribution']) }}</span>
                  <span class="compPercentage" style="color: red" v-else-if="graph_data['yoy_sales_attribution'] < 0">{{ format_percentage(graph_data['yoy_sales_attribution']) }}</span>
                  <span class="compPercentage" style="color: green" v-else>{{ format_percentage(graph_data['yoy_sales_attribution']) }}</span>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <v-divider class="mx-12"></v-divider>
            </v-col>
            <v-col cols="auto" class="ma-0 pa-2">
              <span class="compTitle">{{graph_data['y']}} - Q{{ graph_data['q'] }}</span>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <v-divider class="mx-12"></v-divider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </template>
  
  <script>
  
  // Configs
  // import cpalette from "@/configs/ChartColors/get_color_palette.js";
  
  export default {
    name: "CompPart",
    props: [
      'dataset'
    ],
    components: {
    },
    methods: {
      reload_graph: function () {
        this.graph_visible = false;
        setTimeout(() => (this.graph_visible = true), 10);
      },
      format_percentage: function(stat){
        if(stat == 0 || stat === '-'){
          return "-"
        }
        return (stat * 100).toLocaleString("en-CA",{minimumFractionDigits: 0, maximumFractionDigits: 0, notation: 'compact'}) + "%"
      },
      format_spend: function(stat){
        if(stat == 0 || stat === '-'){
          return "-"
        }
        return stat.toLocaleString("en-CA",{style: "currency", currency: "CAD",  minimumFractionDigits: 1, maximumFractionDigits: 1, notation: 'compact'})
      },
    },
  
    data: () => ({
      graph_visible: false,

      graph_data: null,

      spend_impr_contribution_overview_comments: false,
      spend_impr_contribution_col_size: 12,
      screenHeight: window.innerHeight * (window.innerHeight > 1000 ? 0.7 : 0.6),
    }),
    mounted() {
      this.graph_data = this.dataset;
      if(this.dataset != null){
        this.reload_graph();
      }
    },
    watch: {
      dataset: {
        immediate: false,
        handler: function () {
          this.graph_data = this.dataset;
          console.log(this.dataset)
          if(this.dataset != null){
            this.reload_graph();
          }
        },
        deep: true,
      }
    },
    computed: {},
  };
  </script>
  
  <style scoped>
  .report-subtitle {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 1.5em;
  }
  .chartAlign {
    margin: auto 0
  }
  .compPercentage{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 0.8em;
  }
  .compMainTitle{
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 0.7em;
  }
  .compMainStat{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 1em;
  }
  .compHeader{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 0.8em;
  }
  .compTitle{
    font-family: 'Poppins';
    font-weight: 600;
  }
  </style>
  