<template>
  <v-container
    class="ma-0 pa-0"
    fluid
    style="max-height: calc(100vh - 138px); overflow-y: hidden; width: 100%"
  >
    <div v-if="showAlert">
      <v-alert
        v-model="showAlert"
        border="left"
        close-text="Close Alert"
        dismissible
        :type="alertType"
      >
        {{ alertMsg }}
      </v-alert>
    </div>
    <v-row class="ma-0 pa-0" style="height: 100%">
      <v-col cols="12" class="ma-0 pa-0" style="height: 100%">
        <!-- Title And Highlights Row-->
        <v-row style="min-height: 10%; max-height: 20" class="ma-0 px-6 pt-4 pb-2">
          <!-- Title Column-->
          <v-col cols="12" class="ma-0 pa-0">
            <v-row width="100%" class="ma-0 pa-0" align="center">
              <v-col cols="auto" class="ma-0 pa-0">
                <v-img :src="main_logo" max-height="48px" max-width="80px" contain></v-img>
              </v-col>
              <v-col cols="auto" class="ma-0 pa-0">
                <span class="page-title">Campaign Planning</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="ma-0 pa-0 pr-8" align="center" justify="center">
                <span class="plan-title">{{ plan_name }}</span>
              </v-col>
              <v-col class="ma-0 pa-0 px-1" cols="auto" v-if="goto_optimize">
                <v-btn
                  outlined
                  @click="current_planning_stage = 'optimization_stage'; performance_tab = 'optimization';" color="black">
                  <v-icon color="black" class="mr-2">mdi-playlist-edit</v-icon>
                  <span class="planning_section_action_text">Build Optimizations</span>
                </v-btn>
              </v-col>
              <v-col class="ma-0 pa-0 px-1" cols="auto" v-if="goto_prediction">
                <v-btn
                  outlined color="black"
                  @click="current_planning_stage = 'prediction_stage'; performance_tab = 'prediction';" width="150">
                  <v-icon color="black" class="mr-2">mdi-calculator</v-icon>
                  <span class="planning_section_action_text">Optimize</span>
                </v-btn>
              </v-col>
              <v-col class="ma-0 pa-0 px-1" cols="auto" v-if="new_save">
                <!-- <v-btn
                  outlined color="black"
                  width="150"
                  @click="save_campaign_plan"
                  >
                  <v-icon color="black" class="mr-2">mdi-content-save-outline</v-icon>
                  <span class="planning_section_action_text">Save Plan</span>
                </v-btn> -->
                <v-menu
                  v-model="save_plan_menu"
                  :close-on-content-click="false"
                  :nudge-width="300"
                  offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined color="black"
                      width="150"
                      v-on="on" v-bind="attrs"
                      >
                      <v-icon color="black" class="mr-2">mdi-content-save-outline</v-icon>
                      <span class="planning_section_action_text">Save Plan</span>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="optimization_create_title">Save Plan</v-card-title>
                    <v-card-text class="ma-0 pa-3">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12" class="ma-0 pa-0">
                          <v-text-field v-model="new_plan_title" class="ma-0 pa-0 optimization_calculator_action" label="Plan Name"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined class="optimization_calculator_action" @click="new_plan_title=''; save_plan_menu = false"> Cancel </v-btn>
                      <v-btn outlined class="optimization_calculator_action" @click="save_plan_menu = false; save_campaign_plan();" :disabled="new_plan_title === ''"> Save </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col class="ma-0 pa-0 px-1" cols="auto" v-if="can_reset">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      outlined color="black" width="150px"  @click="reset_planning_setup">
                    <v-icon class="mr-2" color="black" :disabled="can_save" v-on="on" >mdi-undo</v-icon>
                    <span class="planning_section_action_text">Reset</span>
                  </v-btn>
                  </template>
                  <TooltipDescription title="Reset" desc_2=""></TooltipDescription>
                </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0 px-1" cols="auto" v-if="saved_plan">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      outlined color="black" width="150px">
                    <v-icon class="mr-2" color="black" v-on="on" >mdi-pencil</v-icon>
                    <span class="planning_section_action_text">Edit</span>
                  </v-btn>
                  </template>
                  <TooltipDescription title="Reset" desc_2=""></TooltipDescription>
                </v-tooltip>
              </v-col>
              <!-- <v-img :src="main_logo" max-height="48px" max-width="80px" contain></v-img>
              <span class="page-title">Campaign Planning</span> -->
            </v-row>
            <v-row width="100%" class="ma-0 pa-0">
              <v-tabs v-model="performance_tab" align-tabs="start" light>
                <v-tab class="tool-navigation-bar" href="#saved">My Plans</v-tab>
                <v-tab class="tool-navigation-bar" href="#data_setup" :disabled="current_planning_stage != 'data_setup' && current_planning_stage != 'optimization_stage' && current_planning_stage != 'prediction_stage'">Blocking Chart</v-tab>
                <v-tab class="tool-navigation-bar" href="#optimization" :disabled="current_planning_stage != 'optimization_stage' && current_planning_stage != 'prediction_stage'">Optimization Setup</v-tab>
                <v-tab class="tool-navigation-bar" href="#prediction" :disabled="current_planning_stage != 'prediction_stage'">Optimization</v-tab>
              </v-tabs>
            </v-row>
          </v-col>
          <!-- <v-col cols="auto" class="px-2">
            <v-icon color="black" :disabled="!can_save">mdi-content-save-outline</v-icon>
          </v-col> -->
        </v-row>
        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%" v-if="!planning_available" align="center" justify="center">
          <v-col cols="auto">
            <v-progress-circular indeterminate size="100"></v-progress-circular>
          </v-col>
        </v-row>
        <v-tabs-items
          class="ma-0 pa-0"
          v-model="performance_tab"
          style="height: 80%; max-height: 80%; width: 100%"
          v-if="planning_available"
        >
          <v-tab-item
            style="height: 100%; max-height: 100%; width: 100%"
            value="saved"
          >
            <v-row width="100%" style="min-height: 10%" class="ma-0 pa-0">
              <PlanningSaved :project_id="current_project_id" 
                            :data_version="current_project_version"
                            :data_levels="current_project_levels"
                            :project_date_range="current_project_date_range"
                            :project_filters="current_project_filters"
                            v-on:update_campaign_plan_id="update_campaign_plan_id"></PlanningSaved>
            </v-row>
          </v-tab-item>
          <v-tab-item
            style="height: 100%; max-height: 100%; width: 100%"
            value="data_setup"
          >
            <v-row width="100%" style="min-height: 10%" class="ma-0 pa-0">
              <PlanningDataSetup
                :project_date_range="current_project_date_range"
                :data_levels="current_project_levels"
                :data_version="current_project_version"
                :project_id="current_project_id"
                :reset="reset_page"
                :campaign_plan_id="campaign_plan_id"
                v-on:triggerAlert="triggerAlert"
                v-on:date_update="update_planning_date_range"
                v-on:update_campaign_plan_id="update_campaign_plan_id"
                v-on:campaign_plan_retrived="campaign_plan_retrived"
                :lock_changes="lock_optimization_changes"
              ></PlanningDataSetup>
            </v-row>
          </v-tab-item>
          <v-tab-item
            style="height: 100%; max-height: 100%; width: 100%; overflow-y: scroll"
            value="optimization"
          >
            <v-row width="100%" style="min-height: 10%" class="ma-0 pa-0">
              <PlanningOptimization
                :project_date_range="current_project_date_range"
                :data_levels="current_project_levels"
                :data_version="current_project_version"
                :lock_changes="lock_optimization_changes"
                :project_plan_id="campaign_plan_id"
              ></PlanningOptimization>
            </v-row>
          </v-tab-item>
          <v-tab-item
            style="height: 100%; max-height: 100%; width: 100%"
            value="prediction"
          >
            <v-row width="100%" style="min-height: 10%" class="ma-0 pa-0">
              <PlanningPrediction
                :project_date_range="current_project_date_range"
                :data_levels="current_project_levels"
                :data_version="current_project_version"
                :project_plan_id="campaign_plan_id"
              ></PlanningPrediction>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logo from "@/assets/PlanningLogo.svg";

//   import api_caller from "@/javascript/data_info.js"
import PlanningDataSetup from "./PlanningSetup/PlanningDataSetup.vue";
import PlanningOptimization from "./PlanningSetup/PlanningOptimization.vue";
import PlanningPrediction from "./PlanningSetup/PlanningPrediction.vue";
import PlanningSaved from "./PlanningSetup/PlanningSaved.vue"

import TooltipDescription from "@/components/Utility/TooltipDescription.vue";

import api_caller from "@/javascript/data_info.js";
import planning_api_caller from "@/javascript/planning.js";
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS, ALERT_GENERIC_MESSAGE, CAMPAIGN_PLAN_SAVED } from "@/constants/constants";

export default {
  name: "PlanningBasePage",
  components: {
    PlanningDataSetup,
    PlanningOptimization,
    PlanningPrediction,
    PlanningSaved,
    TooltipDescription
  },
  mounted() {
    this.reset_project_data();
  },
  data: () => ({
    alertMsg: null,
    alertType: null,
    showAlert: false,
    main_logo: logo,
    performance_tab: "my_plans",

    current_planning_stage: "my_plans",
    planning_date_set: false,

    //Current Project Variables
    current_project_id: null,
    current_project_version: "V3",
    current_project_date_range: { start_date: "2023-01-01", end_date: "2024-02-29" },
    current_project_levels: {
      data_levels: ["channel", "format"],
      selected_levels: ["channel", "format"],
      level_values: [],
    },
    current_project_filters: {},

    planning_start_date: null,
    planning_end_date: null,
    campaign_plan_id: 19,
    plan_name: '',

    reset_page: false,
    planning_available: true,

    saved_plan: false,


    save_plan_menu: false,
    new_plan_title: ''
  }),
  computed: {
    can_reset(){
      if(!this.planning_date_set || this.saved_plan){
        return false;
      }
      return true;
    },
    goto_blocking_chart(){
      if (this.current_planning_stage == "data_setup" && this.planning_date_set) {
        return true;
      }
      return false;
    },
    goto_optimize() {
      if (this.current_planning_stage == "data_setup" && this.planning_date_set) {
        return true;
      }
      return false;
    },
    goto_prediction() {
      if (this.current_planning_stage == "optimization_stage") {
        return true;
      }
      return false;
    },
    new_save() {
      if (this.current_planning_stage == "prediction_stage" && !this.saved_plan) {
        return true;
      }
      return false;
    },
    can_save() {
      return false;
    },
    lock_optimization_changes() {
      if (this.current_planning_stage == "prediction_stage" || this.saved_plan) {
        return true;
      } else {
        return false;
      }
    },
    lock_blocking_chart_changes() {
      return false;
    },
  },
  methods: {
    reset_project_data: async function () {
      this.planning_available = false;
      if (this.$project.get_val() == null) {
        return;
      }
      let token = await this.$auth.getTokenSilently();
      let project_info = await api_caller
        .get_project_info(token, this.$project.get_val())
        .then((resp) => {
          return resp["data"];
        })
        .catch((e) => {
          console.log(e);
          return "False";
        });
      this.current_project_id = project_info["project_id"];
      this.current_project_version = project_info["version"];
      this.current_project_date_range = {
        start_date: project_info["start_date"],
        end_date: project_info["end_date"],
        min_start_date: project_info["start_date"],
        max_end_date: project_info["end_date"],
      };
      this.current_project_levels = {
        data_levels: project_info["data_levels"],
        selected_levels: [project_info["data_levels"][0]],
        level_values: [],
      };
      this.current_project_filters = project_info['filter_base_values'];
      this.planning_available = true;
    },

    campaign_plan_retrived: function() {
      this.current_planning_stage = "prediction_stage"
    },

    update_planning_date_range: function (date) {
      this.planning_date_set = true;
      this.planning_start_date = date["start_date"];
      this.planning_end_date = date["end_date"];
    },

    reset_planning_setup: async function () {
      this.planning_available = false;
      if (this.campaign_plan_id) {
        let payload = {
          campaign_plan_id: this.campaign_plan_id,
          project_id: this.current_project_id,
          conditions: null,
        };

        let token = await this.$auth.getTokenSilently();
        let result = await planning_api_caller
          .delete_campaign_plan_data(token, payload)
          .then((resp) => {
            if (resp["success"]) {
              return resp["data"];
            }
            return false;
          })
          .catch((e) => {
            console.log("error", e);
            return false;
          });

        if (!result || !result["campaign_plan_id"]) {
          this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
        }
      }
      this.reset_page = true;
      this.planning_date_set = false;
      this.current_planning_stage = "my_plans";
      this.performance_tab = "my_plans";
      this.plan_name = "";
      this.saved_plan = false;
      await this.reset_project_data();
      this.planning_available = true;
      // setTimeout(function () {
      //   this.reset_page = false;
      //   // this.planning_available = true;
      // }, 2000);
      // // this.reset_page = false;
    },

    save_campaign_plan: async function() {
      if (this.campaign_plan_id) {
        let payload = {
          plan_id: this.campaign_plan_id,
          project_id: this.current_project_id,
          status: 'SAVED',
          plan_name: this.new_plan_title
        };

        let token = await this.$auth.getTokenSilently();
        let result = await planning_api_caller
          .update_campaign_plan(token, payload)
          .then((resp) => {
            if (resp["success"]) {
              return resp["data"];
            }
            return false;
          })
          .catch((e) => {
            console.log("error", e);
            return false;
          });

        if (!result || !result["campaign_plan_id"]) {
          this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
        } else {
          this.triggerAlert(ALERT_TYPE_SUCCESS, CAMPAIGN_PLAN_SAVED);
        }
        this.plan_name = this.new_plan_title;
        this.new_plan_title='';
        this.saved_plan = true;
      }
    },

    update_campaign_plan_id: function (campaign_plan_id, saved, name) {
      this.campaign_plan_id = campaign_plan_id;
      this.planning_date_set = true;
      this.performance_tab = 'data_setup';
      this.current_planning_stage = 'data_setup';
      this.saved_plan = saved;
      this.plan_name = name;
    },

    triggerAlert: function (type, message) {
      this.alertType = type ? type : "info";
      this.alertMsg = message;
      this.showAlert = true;
    },
  },
};
</script>

<style scoped>
.page-title {
  font-family: "Poppins" !important;
  font-weight: 800;
  font-size: 2em;
}
.plan-title {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 1.4em;
}
.tool-navigation-bar {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 0.9em !important;
}

.planning_section_action_text {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 0.9em !important;
  color: black;
}

/deep/.v-window__container {
  height: 100% !important;
}
.optimization_calculator_action {
  font-family: 'Poppins' !important;
  font-weight: 400 ;
}
</style>
