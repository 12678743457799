<template>
    <v-card style="height: 100%; max-height: 100%; overflow-y: auto" class="ma-0 pa-0" flat>
      <v-row class="ma-0 pa-0" style="width: 100%;">
          <v-col cols="auto">
            <span class="report-subtitle">ROAS vs Contribution</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" class="ma-0 pa-0" style="height: 10px;">
            <v-divider class="mx-12"></v-divider>
          </v-col>
          <v-col cols="12" class="px-12" v-if="!graph_visible">
              <v-progress-linear
                size="100"
                indeterminate
                color="primary"
              ></v-progress-linear>
          </v-col>
          <v-col v-if="graph_visible" :cols="main_graph_section_cols" class="ma-0 pa-0" style="height: calc(100% - 10px);">
            <v-row class="ma-0 pa-0" style="height: 100%;">
              <!-- <v-col :cols="minor_graph_section_cols">
                <v-col cols="12">
                  <span class="graph-title">ROAS</span>
                </v-col>
                <v-col cols="12">
                    <div
                      :style="{
                        height: screenHeight + 'px',
                      }"
                    >   
                    <GChart
                      type="ColumnChart"
                      :data="graph_data['roas']"
                      :options="graph_config"
                      :resizeDebounce="50"
                      class="performanceChart"
                    />
                  </div>
                </v-col>
              </v-col> -->
              <!-- <v-col :cols="minor_graph_section_cols"> -->
                <!-- <v-col cols="12">
                  <span class="graph-title">Contribution</span>
                </v-col> -->
                <v-col cols="12">
                  <div
                      :style="{
                        height: screenHeight + 'px',
                      }"
                    >   
                      <GChart
                        type="ComboChart"
                        :data="graph_data['contribution']"
                        :options="graph_config"
                        :resizeDebounce="50"
                        class="performanceChart"
                      />
                  </div>
                </v-col>
              <!-- </v-col> -->
            </v-row>
          </v-col>
      </v-row>
          <v-spacer></v-spacer>
          <v-col cols="4" v-if="comments_section">
            <CommentSection v-on:close_comments="close_comments" :comments_persistent="comments_persistent"></CommentSection>
          </v-col>
    </v-card>
  </template>
  
  <script>
  import { GChart } from "vue-google-charts/legacy";
  
  // Configs
  import columnChartConfig from "@/configs/Performance/performance_roi_contribution_overview_config.json";
  // import cpalette from "@/configs/ChartColors/get_color_palette.js";
  import CommentSection from "./comment_section.vue"
  
  export default {
    name: "ROASContribution",
    props: [
      'data_version',
      'dataset',
      'comments_persistent',
      'color_palette'
    ],
    components: {
      GChart,
      CommentSection
    },
    methods: {
      reload_graph: function () {
        this.graph_visible = false;
        setTimeout(() => (this.graph_visible = true), 10);
      }
    },
  
    data: () => ({
      graph_visible: false,
  
      graph_config: columnChartConfig,
      graph_data: null,

      comments_section: false,
      main_graph_section_cols: 12,
      minor_graph_section_cols: 12,
      screenHeight: window.innerHeight * (window.innerHeight > 1000 ? 0.7 : 0.7),
      colors: [
        "#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", 
        "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf", 
        "#aec7e8", "#ffbb78", "#98df8a", "#ff9896", "#c5b0d5", 
        "#c49c94", "#f7b6d2", "#c7c7c7", "#dbdb8d", "#9edae5", 
        "#7f7f7f", "#bcbd22", "#17becf", "#aec7e8", "#ffbb78", 
        "#98df8a", "#ff9896", "#c5b0d5", "#c49c94", "#f7b6d2", 
        "#c7c7c7", "#dbdb8d", "#9edae5", "#7f7f7f", "#bcbd22", 
        "#17becf", "#aec7e8", "#ffbb78", "#98df8a", "#ff9896", 
        "#c5b0d5", "#c49c94", "#f7b6d2", "#c7c7c7", "#dbdb8d", 
        "#9edae5", "#7f7f7f", "#bcbd22", "#17becf", "#aec7e8"
      ]
    }),
    mounted() {
      this.graph_data = this.dataset;
      if(this.dataset != null){
        this.reload_graph();
      }
    },
    watch: {
      dataset: {
        immediate: false,
        handler: function () {
          this.graph_data = this.dataset;
          if(this.dataset != null){
            this.reload_graph();
          }
        },
        deep: true,
      }
    },
    computed: {}
  };
  </script>
  
  <style scoped>
  .main-page-title {
    /* line-height: 5em; */
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1.5em;
  }
  .report-subtitle {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 1.5em;
  }
  
  .graph-title {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 0.9em;
  }
  
  .report-version {
    font-family: "Poppins";
    font-weight: 600;
    align-items: center;
    /* font-size: 0.5em; */
    /* width: 50px; */
  }
  
  .performanceChart {
    height: 100%;
    width: 100%;
  }
  
  .chartHeader {
    .chartHeaderText {
      font-family: "Poppins" !important;
      font-weight: 600;
      font-size: 1.5em;
    }
  
    display: flex;
  }
  
  .toolBar {
    float: right;
  }
  
  .toolButton {
    padding: 0 32px;
    width: 50px;
    color: black;
  }
  
  ::v-deep .v-overlay__content {
    position: absolute;
    right: 0;
    height: 100%;
    width: 30%;
  }
  
  .overlayField {
    padding: 0 16px;
  }
  
  .overlayButton {
    float: right;
  }
  
  .chartWithOverlay {
    /* position: relative; */
    width: 100%;
    /* height: 500px; */
  }
  .miniChartWithOverlay {
    /* position: relative; */
    width: 100%;
    height: 75px;
  }
  
  .metricToggleOverlay {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 12%;
    left: 90px;
  }
  
  .metricToggleRow {
    margin-left: 0;
    margin-right: auto;
  }
  .metric_toolbar_title {
    font-weight: 800 !important;
  }
  .metric_toolbar_subtitle {
    font-weight: 600 !important;
  }
  .metric_toolbar_metric_title {
    line-height: 1em;
    font-weight: 200 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: calc(80%);
    text-align: left;
    overflow: hidden;
  }
  
  .tooltip_description {
    font-family: "Poppins" !important;
    font-weight: 200;
  }
  .long_description {
    font-family: "Poppins" !important;
  }
  
  .expansionPanel {
    overflow: none;
  }
  
  .reportCommentContent {
    max-height: 350px;
    overflow-y: auto;
  }
  
  .reportCommentInputField {
    float: right;
    width: 90%;
  }
  </style>
  