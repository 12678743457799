<template>
  <v-app>
    <!-- 
      User Nav Drawer 
    -->
    <v-navigation-drawer
      v-if="$auth.isAuthenticated && !$route.meta.hideNavbar"
      v-model="user_drawer"
      temporary
      absolute
      app
      color="primary"
      dark
      right
    >
      <template v-slot:prepend>
        <v-list-item one-line>
          <v-list-item-avatar style="background-color: #ffffff50">
            <img
              :src="$auth.user.picture"
              :alt="$auth.user.name"
            >
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="docma-text-navigation-user">{{
              $auth.user.name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ma-0 pa-0 mx-6"></v-divider>
        <v-list-item one-line>
          <v-list-item-avatar><v-icon>mdi-account-group-outline</v-icon></v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="docma-text-navigation-user">
              <div class="organization-title">
                <div class="organization-title-content">
                  {{user_orgs && user_orgs[$auth.user.org_id] ? user_orgs[$auth.user.org_id]['display_name'] : $auth.user.org_id}}
                </div>
                <v-tooltip left v-if="Object.keys(user_orgs).length > 1">
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="toolButton" v-on="on" @click="switchOrg">
                      <v-icon dark color="#FFFFFF">mdi-autorenew</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip_description" style="display: inline-block">
                    Switch Organization
                  </span>
                </v-tooltip>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-divider class="ma-0 pa-0 mx-6"></v-divider>
        <v-list-item one-line>
          <v-list-item-avatar class="mx-0 px-0"><v-icon class="mx-0 px-0">mdi-chevron-right</v-icon></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="docma-text-navigation-user">
              <div class="organization-title">
                <div class="organization-title-content">
                  My System Preferences
                </div>
              </div>
            </v-list-item-title>
          </v-list-item-content>
          <v-row>
            <v-col cols="12">
              <v-switch v-model="stock_ticker"></v-switch>
            </v-col>
          </v-row>
        </v-list-item> -->
      </template>
      <v-divider class="ma-0 pa-0 mx-6"></v-divider>
      <v-row class="ma-0 pa-0">
        <v-row class="ma-0 pa-0 pl-3" align="center">
          <span class="Brand-name-title">My Brands</span>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="ma-0 px-3 py-0">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn icon class="toolButton" v-on="on" @click="get_my_projects">
                  <v-icon color="#FFFFFF">mdi-folder-refresh-outline</v-icon>
                </v-btn>
              </template>
              <span class="tooltip_description" style="display: inline-block">
                Refresh My Brands
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-for="(env, idx) in environments" :key="idx" class="ma-0 pa-0">
          <v-col cols="12" class="ma-0 pt-0">
            <span class="Environment-name-title" v-if="environments.length > 1">{{
              env
            }}</span>
          </v-col>
          <v-col
            cols="12"
            class="ma-0 py-0"
            v-for="(brand, jdx) in my_brands[env]"
            :key="jdx"
          >
            <v-switch
              class="ma-0 pa-0 Brand-name-title"
              dense
              :label="brand[1]"
              v-model="tool_state"
              :value="brand[0]"
              @click="tool_state = brand[0]"
            ></v-switch>
          </v-col>
        </v-row>
      </v-row>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn class="docma-text-navigation-logout" block @click="logout">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <!-- 
      APP Bar
     -->
    <v-app-bar
      v-if="$auth.isAuthenticated && !$route.meta.hideNavbar"
      app
      color="primary"
      dark
      :height="stock_ticker ? '90px': '45px'"
      extension-height="48px"
      class="ma-0 pa-0"
    >
      <v-row class="ma-0 pa-0 px-3" max-height="100%">
        <v-col cols="12" class="ma-0 pa-0" v-if="sliderElements.length > 0 && stock_ticker">
          <vue-marquee-slider
          id="marquee-slider"
          :speed="20000 * sliderElements.length"
          :width="1600"
          class="marquee_slider"
          
          >
              <div
                class="sliderElement" v-for="(elm, idx) in sliderElements" :key="idx"
              >
                <div class="sliderElement" >
                  <v-row class="ma-0 pa-0" width="100vw" align="center" justify="center">
                    <v-col cols="auto" align="center" class="ma-0 pa-0 pr-3">
                      <v-icon color="white">mdi-rhombus</v-icon>
                    </v-col>
                    <v-col cols="auto" align="center" class="ma-0 pa-0">
                      <span>{{ elm.name }} </span>
                    </v-col>
                    <v-col cols="8" class="ma-0 pa-0">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="4" align="center" class="ma-0 pa-0">
                          <span>Spend @ </span>
                          <span :class="elm.Spend['value'] >= 0 ? 'sliderElementValueGreen' : 'sliderElementValueRed'">
                            {{ elm.Spend['value'].toLocaleString("en-CA", { style: "currency", currency: "CAD", minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                            <v-icon v-if="elm.Spend['value'] >= 0" class="sliderElementValueGreen sliderElementIcon" large>mdi-menu-up</v-icon>
                            <v-icon v-else class="sliderElementValueRed sliderElementIcon" large>mdi-menu-down</v-icon>
                            {{ elm.Spend['percentage'].toLocaleString("en-CA", { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                          </span>
                        </v-col>
                        <v-col cols="4" align="center" class="ma-0 pa-0">
                          <span>ROI @ </span>
                          <span :class="elm.ROI['value'] >= 0 ? 'sliderElementValueGreen' : 'sliderElementValueRed'">
                            {{ elm.ROI['value'].toLocaleString("en-CA", { style: "currency", currency: "CAD", minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                            <v-icon v-if="elm.ROI['value'] >= 0" class="sliderElementValueGreen sliderElementIcon" large>mdi-menu-up</v-icon>
                            <v-icon v-else class="sliderElementValueRed sliderElementIcon" large>mdi-menu-down</v-icon>
                            {{ elm.ROI['percentage'].toLocaleString("en-CA", { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                          </span>
                        </v-col>
                        <v-col cols="4" align="center" class="ma-0 pa-0">
                          <span>Volume @ </span>
                          <span :class="elm['Sales Attribution']['value'] >= 0 ? 'sliderElementValueGreen' : 'sliderElementValueRed'">
                            {{ elm['Sales Attribution']['value'].toLocaleString("en-CA", { style: "currency", currency: "CAD", minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                            <v-icon v-if="elm['Sales Attribution']['value'] >= 0" class="sliderElementValueGreen sliderElementIcon" large>mdi-menu-up</v-icon>
                            <v-icon v-else class="sliderElementValueRed sliderElementIcon" large>mdi-menu-down</v-icon>
                            {{ elm['Sales Attribution']['percentage'].toLocaleString("en-CA", { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="auto" align="center" class="ma-0 pa-0 pl-3">
                      <v-icon color="white">mdi-rhombus</v-icon>
                    </v-col>
                  </v-row>
                </div>  
              </div>
          </vue-marquee-slider>
        </v-col>
        <!-- <v-col cols="12" class="sliderElement" v-else>

        </v-col> -->
        <v-col cols="12" class="ma-0 pa-0">
          <v-row class="ma-0 pa-0" style="max-width: calc(100vw - 24px);">
            <v-col cols="4" class="ma-0 pa-0" style="max-height: 45px">
              <v-img
                :src="require(`${main_logo_inverterd}`)"
                contain
                max-width="150px"
                max-height="30px"
              ></v-img>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-0 pa-0 docma-text-navigation-bar-btn"
              text
              plain
              dark
              @click="user_drawer = true"
            >
              <span class="docma-text-navigation-bar-text"
                >Welcome {{ $auth.user.given_name }}</span
              >
              <v-icon>mdi-account-outline</v-icon>
            </v-btn>
            <v-divider vertical color="white" class="ma-0 pa-0 mx-3"></v-divider>
            <v-btn class="ma-0 pa-0 docma-text-navigation-bar-btn" text plain dark disabled>
              <span class="docma-text-navigation-bar-text">Help</span>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
            <v-divider vertical color="white" class="ma-0 pa-0 mx-3"></v-divider>
            <v-btn
              class="ma-0 pa-0 docma-text-navigation-bar-btn"
              text
              plain
              dark
              disabled
              @click="feedback = true"
            >
              <span class="docma-text-navigation-bar-text">Feedback</span>
              <v-icon>mdi-comment-quote-outline</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <template v-slot:extension>
        <v-row class="ma-0 pa-0" style="height: 100%">
          <v-col cols="10" class="ma-0 pa-0 px-3" style="max-height: 48px">
            <v-tabs v-model="mainTab" align-tabs="start" background-color="#1b1b1b">
              <v-tab class="docma-tool-navigation-bar" to="/audience">Audiences <v-icon class="ml-2">mdi-professional-hexagon</v-icon></v-tab>
              <v-tab class="docma-tool-navigation-bar" to="/planning">Campaign Planning <v-icon class="ml-2">mdi-professional-hexagon</v-icon></v-tab>
              <v-tab class="docma-tool-navigation-bar" to="/performance">Performance</v-tab>
            </v-tabs>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-icon>mdi-bell</v-icon>
          </v-col>
        </v-row>
      </template>
    </v-app-bar>
    <!-- 
      Feedback
    -->
    <v-overlay v-model="feedback">
      <FeedbackCard v-on:close="feedback = false"></FeedbackCard>
    </v-overlay>
    <!-- 
      APP Content
     -->
    <v-main
      style="
        height: calc(100vh-93px);
        max-height: calc(100vh-93px);
        overflow-y: hidden;
        overflow-x: hidden;
      "
      v-if="environment_enabled"
    >
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import FeedbackCard from "./views/FeedbackCard.vue";
import api_caller from "./javascript/data_info.js";
import retrieval_api_caller from "./javascript/data_retrieval.js";
// import PerformanceBasePage from './components/Performance/PerformanceBasePage.vue';

export default {
  name: "App",

  components: {
    FeedbackCard,
  },
  mounted() {
    if (this.$auth.isAuthenticated) {
      this.user_first_name = this.$auth.user.given_name;
      this.user_full_name = this.$auth.user.name;
      this.get_my_projects();
    }
  },
  updated() {
    if (this.$auth.isAuthenticated && !this.brands_pulled) {
      this.get_my_projects();
      this.get_my_orgs();
    }
  },
  watch: {
    tool_state: {
      handler: function () {
        this.$project.set_val(this.tool_state);
        this.reset_environment();
      },
    },
  },
  data: () => ({
    main_logo_inverterd: "./assets/docma-logo-white-red.svg",

    logged_in: false,
    user_drawer: false,
    feedback: false,
    user_orgs: {},

    user_first_name: "",
    user_full_name: "",

    tool_state: null,

    my_brands: {},
    environments: [],

    rating: 5,
    rating_follow_up: true,

    rating_success: false,
    rating_error: false,

    mainTab: 2,

    environment_enabled: true,

    brands_pulled: false,

    sliderElements: [],

    //System Preferences
    stock_ticker: true
  }),
  methods: {
    reset_environment: function () {
      this.environment_enabled = false;
      this.get_ticker();
      setTimeout(() => (this.environment_enabled = true), 500);
    },
    get_ticker: async function(){
      let token = await this.$auth.getTokenSilently();
      let ticker = await retrieval_api_caller.get_ticker_data(token, this.tool_state)
        .then((resp) => {
          return resp["data"];
        })
        .catch((e) => {
          console.error(e);
          return false;
        });
      if (!ticker) {
        return;
      }
      this.process_portfolio_quarter_delta(ticker['portfolio_data'], ticker['portfolio_data_prev_quarter']);
    },
    process_portfolio_quarter_delta(portfolio_data, portfolio_data_prev_quarter) {
      let ads_unit_levels = Object.keys(portfolio_data['table_levels'])
      function is_ads_unit(value) {
        for(let i = 0; i < ads_unit_levels.length; i++) {
          if(value[ads_unit_levels[i]] == null || value[ads_unit_levels[i]] == '') {
            return false;
          }
        }
        return true;
      }

      if(portfolio_data['table_content'] && portfolio_data_prev_quarter['table_content']) {
        let table_metrics = [
          {'value': 'spend', 'text': 'Spend'},
          {'value': 'roi', 'text': 'ROI'},
          {'value': 'sales_attribution', 'text': 'Sales Attribution'}
        ]

        let tmp_portfolio_data = {... portfolio_data};
        tmp_portfolio_data['table_content'] = tmp_portfolio_data['table_content'].filter(is_ads_unit)
      
        let tmp_portfolio_data_prev_qrt = {... portfolio_data_prev_quarter}
        tmp_portfolio_data_prev_qrt['table_content'] = tmp_portfolio_data_prev_qrt['table_content'].filter(is_ads_unit)

        let portfolio_data_delta = {'table_headers': [], 'table_content': [], 'table_levels': ads_unit_levels, 'table_metrics': table_metrics}
        portfolio_data_delta['table_headers'] = ads_unit_levels.concat(table_metrics.map(a => a.value))

        for (let i = 0; i < tmp_portfolio_data['table_content'].length; i++) {
          let row = {}
          ads_unit_levels.forEach((level)=> {
            row[level] = tmp_portfolio_data['table_content'][i][level]
          })
          if(tmp_portfolio_data['table_content'][i] && tmp_portfolio_data_prev_qrt['table_content'][i]) {
            let old_data = tmp_portfolio_data_prev_qrt['table_content'][i]
            let new_data = tmp_portfolio_data['table_content'][i]
            row['spend'] = {
              'value': new_data['spend'] - old_data['spend'],
              'percentage': old_data['spend'] == 0 ? 0 : (new_data['spend'] - old_data['spend']) / old_data['spend']
            }
            row['roi'] = {
              'value': new_data['roi'] - old_data['roi'],
              'percentage': old_data['roi'] == 0 ? 0 : (new_data['roi'] - old_data['roi']) / old_data['roi']
            }
            row['sales_attribution'] = {
              'value': new_data['sales_attribution'] - old_data['sales_attribution'],
              'percentage': old_data['sales_attribution'] == 0 ? 0 : (new_data['sales_attribution'] - old_data['sales_attribution']) / old_data['sales_attribution']
            }
            portfolio_data_delta['table_content'].push(row)
          }
        }
        this.portfolio_quarter_delta_update(portfolio_data_delta);
        }
    },
    portfolio_quarter_delta_update: function(portfolio_delta_data) {
      if(portfolio_delta_data['table_content']) {

        let newSliderElements = []

        portfolio_delta_data['table_content'].forEach((row) => {
          let ads_units_name = []
          portfolio_delta_data['table_levels'].forEach((level) => {
            ads_units_name.push(row[level])
          })
          let element_prex = ads_units_name.join("_")

          let element_text_overview = {
            'name': element_prex,
            'vol': 0,
            'roi': 0,
            'spend': 0
          };

          portfolio_delta_data['table_metrics'].forEach((metric) => {
            element_text_overview[metric.text] = row[metric.value];
            // newSliderElements.push({
            //   'name': element_prex + "-" + metric.text,
            //   'value': row[metric.value]
            // })
          })
          newSliderElements.push(element_text_overview);
        })
        this.sliderElements = newSliderElements 
      }
    },
    get_my_projects: async function () {
      let token = await this.$auth.getTokenSilently();
      let projects = await api_caller
        .get_saved_projects(token)
        .then((resp) => {
          return resp["data"];
        })
        .catch((e) => {
          console.error(e);
          return false;
        });
      if (!projects) {
        return;
      }
      this.environments = Object.keys(projects);
      this.my_brands = projects;
      let project_exists = false;
      let existing_project = null;
      for(let env of this.environments){
        for(let brand of projects[env]){
          if(brand[0] === this.tool_state){
            project_exists = true;
            existing_project = brand;
          }
        }
      }
      if (this.environments.length > 0 && project_exists) {
        this.tool_state = existing_project[0];
        this.reset_environment();
      }
      else if(this.environments.length > 0){
        this.tool_state = this.my_brands[this.environments[0]][0][0];
      }
      this.brands_pulled = true;
    },
    logout: function () {
      this.$auth.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    },
    get_my_orgs: async function () {
      let token = await this.$auth.getTokenSilently();
      let userOrgs = await api_caller
        .get_user_orgs(token, this.$auth.user.sub)
        .then((resp) => {
          return resp["data"];
        })
        .catch((e) => {
          console.error(e);
          return false;
        });
      if(userOrgs) {
        this.user_orgs = userOrgs
      }
    },
    switchOrg: function () {
      this.$auth.loginWithRedirect()
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins:200,400,600,800");
@import url("https://fonts.googleapis.com/css?family=Amiko:400,600,700");
.docma-tool-navigation-bar {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 0.9em !important;
  background-color: #1b1b1b !important;
}
.docma-text-navigation-bar-text {
  font-family: "Amiko" !important;
  font-size: 0.7em;
}
.docma-text-navigation-bar-btn {
  height: 30px !important;
}
.docma-text-navigation-logout {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 1em !important;
}
.docma-text-navigation-user {
  font-family: "Poppins" !important;
  font-weight: 200 !important;
  font-size: 1.1em !important;
}
.Environment-name-title {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 1.2em !important;
  color: white;
}
.Brand-name-title {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 1em !important;
  color: white;
}
.docma-text-rating-main-title {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 1.2em;
}
.docma-text-rating-title {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 0.9em;
}
.docma-text-rating-text {
  font-family: "Poppins" !important;
  font-weight: 200;
  font-size: 1.1em;
}
.loginFormAlerts {
  font-family: "Poppins" !important;
  position: absolute;
  width: 100%;
  z-index: 4;
}
.organization-title {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 0.7em !important;
  color: white;
  display: flex;
  max-width: 200px;
  align: left;

  .organization-title-content {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: auto;
    margin: auto;
  }
}
.organization-content {
  font-size: 1em !important;
  padding-bottom: 12px;
}

/deep/ .v-toolbar__content {
  padding: 0px !important;
}
/deep/ .v-toolbar__extension {
  padding: 0px !important;
}

.marquee_slider {
  background: #1b1b1b !important;
}

.sliderElement {
  font-family: "Poppins" !important;
  font-size: 1em;
  font-weight: 400;
  color: #ffffff;
  height: 40px;
  line-height: 40px;
  .sliderElementValueGreen {
    color: green
  }
  .sliderElementValueRed {
    color: red
  }
  .sliderElementIcon {
    padding-bottom: 4px;
    font-size: 1.25em;
  }
}
.theme--dark.v-tabs > .v-tabs-bar {
    background-color: #1B1B1B !important;
}
</style>
