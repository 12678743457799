import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueMarqueeSlider from 'vue-marquee-slider';
// import { getAuth } from 'firebase/auth';
// import firebase_app from './plugins/firebase'

Vue.config.productionTip = false

// Import the Auth0 configuration
import { domain, clientId, authorizationParams } from '../auth_config.json';

// Import the plugin
import { Auth0Plugin } from './auth';
Vue.prototype.$project = {
  val: null,
  set_val: function(token){
      this.val = token;
  },
  get_val: function(){
      return this.val;
  }};

Vue.prototype.$client = {
  val: "0001",
  set_val: function(token){
      this.val = token;
  },
  get_val: function(){
      return this.val;
  }};
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  authorizationParams,
  onRedirectCallback: appState => {
    history.replaceState({}, document.title, window.location.pathname);
    console.log("on redirect callback")
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : "/performance"
    );
  }
});
Vue.use(VueMarqueeSlider);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');