import axios from 'axios'

// const api_endpoint = 'https://enterprise.api.docmasweetspot.ca'
const api_endpoint = 'https://staging.enterprise.api.docmasweetspot.ca'
// const api_endpoint = 'http://127.0.0.1:5001'


function get_prediction_data(token, payload){
    let api_url = api_endpoint+'/planning/prediction';
    return axios_post_call(api_url,payload,token);
}

function create_campaign_plan(token, payload){
    let api_url = api_endpoint+'/planning/create_campaign_plan';
    return axios_post_call(api_url,payload,token);
}

function update_campaign_plan(token, payload){
    let api_url = api_endpoint+'/planning/edit_campaign_plan';
    return axios_post_call(api_url,payload,token);
}

function get_campaign_plan(token, payload){
    let api_url = api_endpoint+'/planning/get_campaign_plan';
    return axios_post_call(api_url,payload,token);
}

function delete_campaign_plan_data(token, payload){
    let api_url = api_endpoint+'/planning/delete_campaign_plan_data';
    return axios_post_call(api_url,payload,token);
}

function edit_campaign_plan_data(token, payload){
    let api_url = api_endpoint+'/planning/edit_campaign_plan_data';
    return axios_post_call(api_url,payload,token);
}

function get_campaign_plan_data(token, payload){
    let api_url = api_endpoint+'/planning/get_campaign_plan_data';
    return axios_post_call(api_url,payload,token);
}


function get_optimization_info(token, payload){
    let api_url = api_endpoint + '/planning/optimization/info';
    return axios_post_call(api_url, payload, token);
}

function add_new_optimization(token, payload){
    let api_url = api_endpoint + '/planning/optimization/add';
    return axios_post_call(api_url, payload, token);
}

function delete_optimization(token, payload){
    let api_url = api_endpoint + '/planning/optimization/delete';
    return axios_post_call(api_url, payload, token);
}

function update_optimization(token, payload){
    let api_url = api_endpoint + '/planning/optimization/update';
    return axios_post_call(api_url, payload, token);
}

function get_optimization_data(token, payload){
    let api_url = api_endpoint + '/planning/optimization/data';
    return axios_post_call(api_url, payload, token);
}

function edit_response_curve(token, payload){
    let api_url = api_endpoint + '/planning/optimization/response_curve_edit';
    return axios_post_call(api_url, payload, token)
}

/*
Base Caller functions
*/
function axios_post_call(api_url,data,token){
    return new Promise(function(resolve,reject){
        axios.post(api_url, data, {headers: {Authorization: `Bearer ${token}`}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        }).catch((e)=>{
            reject(api_url+e);
        })
    })
}

export default {
    get_prediction_data,
    create_campaign_plan,
    update_campaign_plan,
    get_campaign_plan,
    delete_campaign_plan_data,
    edit_campaign_plan_data,
    get_campaign_plan_data,
    get_optimization_info,
    get_optimization_data,
    add_new_optimization,
    delete_optimization,
    update_optimization,
    edit_response_curve
}