<template>
    <v-row class="ma-0 pa-0" align="center" justify="center" style="height: 100%;">
        <v-col cols="5" class="ma-0 pa-1 pl-6"  align="center" justify="center">
            <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                    <span class="statistic_value_text">
                        {{ value == null ? statistic_value : format_spend(value) }} 
                    </span>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                    <span class="statistic_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
                </v-col>
            </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="ma-0 pa-1">
            <v-row class="ma-0 pa-0" align="center">
                <v-col cols="12" class="ma-0 pa-0" align="center">
                    <span class="statistic_value_change_text" v-if="change > 0" style="color: green;">
                        {{ format_percentage(change) }}
                    </span>
                    <span class="statistic_value_change_text" v-else-if="change < 0" style="color: red;">
                        {{ format_percentage(change) }}
                    </span>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0" align="center">
                    <span class="statistic_change_title">MoM</span>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="auto" class="ma-0 pa-1">
            <v-row class="ma-0 pa-0" align="center">
                <v-col cols="12" class="ma-0 pa-0" align="center">
                    <span class="statistic_value_change_text" v-if="change > 0" style="color: green;">
                        {{ format_percentage(change) }}
                    </span>
                    <span class="statistic_value_change_text" v-else-if="change < 0" style="color: red;">
                        {{ format_percentage(change) }}
                    </span>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0" align="center">
                    <span class="statistic_change_title" >YoY</span>
                </v-col>
            </v-row>
        </v-col>
        <!-- <v-col cols="8" class="ma-0 pa-1" align="center">
            <span class="statistic_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
        <v-col cols="2" class="ma-0 pa-0" align="center">
            <span class="statistic_change_title">MoM</span>
        </v-col>
        <v-col cols="2" class="ma-0 pa-0" align="center">
            <span class="statistic_change_title">YoY</span>
        </v-col> -->
        <!-- <v-spacer></v-spacer> -->
    </v-row>
</template>

<script>
    export default {
        name: 'SalesOverviewStatistic',
        props: [
            'statistic_title_name',
            'value',
            'change'
        ],
        data: () => ({
            statistic_value: '-',
            statistic_name: 'Statistic',
        }),
        methods: {
            format_percentage: function(stat){
                if(stat == 0 || stat === '-'){
                    return "-"
                }
                return (stat * 100).toLocaleString("en-CA",{minimumFractionDigits: 1, maximumFractionDigits: 1, notation: 'compact'}) + "%"
            },
            format_spend: function(stat){
                if(stat == 0 || stat === '-'){
                    return "-"
                }
                return stat.toLocaleString("en-CA",{style: "currency", currency: "CAD",  minimumFractionDigits: 1, maximumFractionDigits: 2, notation: 'compact'})
            }
        }
    }
</script>

<style scoped>
    .statistic_value_text{
        font-family: "Poppins" !important;
        font-weight: 600;
        font-size: 18pt;
    }
    .statistic_value_change_text{
        font-family: "Poppins" !important;
        font-weight: 200;
        font-size: 9pt;
    }
    .statistic_change_title{
        font-family: "Poppins";
        font-weight: 200;
        font-size: 9pt;
    }
    .statistic_title{
        font-family: "Poppins";
        font-weight: 400;
        font-size: 10pt;
    }
</style>